<template>
    <div>
        <b-form-input ref="control" :id="id" :name="name" :value="val" @input="onInput"></b-form-input>
    </div>
</template>
<script>
export default {
    props: {
        value: Array,
        name: String,
        id: String
    },
    computed: {
        val() {
            return this.value ? this.value.join(',') : ''
        }
    },
    methods: {
        onInput(e) {
            let data = e.split(',');
            this.$emit('input', data);
            this.$emit('change', data);
        }
    }
}
</script>