export default [
	{
		slug: 'dashboard',		
		url: '/dashboard',
		icon: {
			element: 'b-icon-grid'
		}
	},
	{
		slug: 'domains',
		url: '/domains',
		icon: {
			element: 'b-icon-inboxes'
		}
	},
	{
		slug: 'profile',
		icon: {
			element: 'b-icon-person'
		},
		child: [
			{
				slug: 'profile-about',
				url: '/profile',
				icon: {
					element: 'b-icon-person'
				}
			},
			{
				slug: 'profile-settings',
				url: '/profile/settings',
				icon: {
					element: 'b-icon-gear'
				}
			},
		]
	}
]