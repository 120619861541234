<template>
    <div>
        <div class="text-center" v-if="!loaded">
            <b-spinner :label="$t('loading.title')"></b-spinner>
        </div>
        <div v-if="hasError">
            <b-alert variant="danger" show v-html="hasError"></b-alert>
        </div>
        <div v-if="loaded && !hasError">            
            <b-alert variant="info" show v-if="!domain.plugins.length">{{$t('plugin.no_plugins')}}</b-alert>
            <div class="row" v-else>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" v-for="plugin in domain.plugins" :key="plugin.id">
                    <b-card
                    v-if="loadedItem!=plugin.id"
                    :bg-variant="plugin.active && plugin.enabled? 'default' : 'light'"              
                    :border-variant="plugin.active && plugin.enabled ? 'default' : 'light'"                       
                    class="mb-2"
                    style="min-height: 220px">     
                    <h3 class="px-2 h4 app-plugin-name">
                        <span class="row jc-between ai-center">
                            <a href="#" role="button" @click.prevent="openPluginPopup(plugin)">
                                <span class="plugin-name">{{plugin.name}}</span>
                                <small>
                                    <b-badge variant="light" v-if="plugin.active_plugin.version">{{plugin.active_plugin.version}} v</b-badge><b-badge variant="info" v-else>{{plugin.plugin_version}} v</b-badge>
                                </small>
                            </a>
                            <small>
                                <b-badge class="font-weight-normal" v-if="plugin.active && plugin.enabled && domain.active && plugin.configurated" variant="success">{{$t('status.on')}} <span class="sr-only">{{$t('plugin.enabled')}}</span></b-badge>
                                <b-badge class="font-weight-normal" v-else variant="danger">{{$t('status.off')}} <span class="sr-only">{{$t('plugin.disabled')}}</span></b-badge>
                            </small>
                        </span>
                    </h3>
                    <b-card-text>
                        {{plugin.description}} 
                        <div v-if="plugin.dep_libs">
                            <small>{{$t('plugins.dep_libs_title')}}:</small><small class="text-muted" v-for="dep in plugin.dep_libs"> <span>{{dep}}, </span></small>
                        </div>
                    </b-card-text>
                    <div v-if="plugin.has_update">
                        <hr>
                        <a href="#" type="button" @click.prevent="onUpdateDomainPlugin(plugin)" class="block alert alert-warning">
                            <baseIcon id="update" :options="{height: 20}" :presentation="true"></baseIcon>
                            {{$t('plugin.messages.has_update')}}
                        </a>
                    </div>
                    <template #footer>
                        <div class="row jc-between ai-center">
                            <b-button :disabled="!domain.active" v-if="plugin.has_config" @click="configPlugin(plugin)" variant="primary" size="sm">{{$t('btns.config')}}</b-button>
                            <span>
                                <b-button :disabled="!domain.active" v-if="plugin.active && plugin.configurated" size="sm" @click="statusSwitcher(plugin, false)" variant="outline-danger">{{$t('btns.disable')}}</b-button>
                                <b-button :disabled="!domain.active" v-if="!plugin.active && plugin.configurated" size="sm" @click="statusSwitcher(plugin, true)" variant="outline-success">{{$t('btns.enable')}}</b-button>
                                <b-button size="sm" @click="removePluginFromDomain(plugin)" variant="link"><baseIcon :options="{height: 20}" id="trash" :presentation="true"></baseIcon> {{$t('btns.remove')}}</b-button>
                            </span>
                        </div>
                    </template>                   
                </b-card>
                <b-card v-else>
                    <div class="text-center">
                        <b-spinner :label="$t('loading.title')"></b-spinner>
                    </div>
                </b-card>
                </div>
            </div>
        </div>

        <b-modal v-model="confirmModal.show" hide-footer :title="confirmModal.title">
            <b-alert show variant="danger" v-html="confirmModal.message"></b-alert>
            <hr>
            <div class="text-center">
            <b-btn variant="primary" @click.prevent="confirmOk" v-html="$t('btns.ok')"></b-btn>
            <b-btn variant="light" @click.prevent="confirmModal.show=false" v-html="$t('btns.cancel')"></b-btn>
            </div>
        </b-modal>
        <b-modal size="lg" scrollable v-model="configModal.show" hide-footer :title="configModal.title">
            <dynamicForm :api_type="apiType" :plugin_version="activePluginVersion" :domain_id="domainData.id" :plugin_id="activePluginConfiguration.id" @save="saveActivePluginConfig" @cancel="cancelActivePluginConfig"></dynamicForm>
        </b-modal>


        <b-modal size="xs" scrollable v-model="activePlugin.show" hide-footer :title="activePlugin.plugin.name">

            <p v-if="activePlugin.plugin.domains_limit != -1" v-html="$t('plugin.domain_limit.description', activePlugin.plugin)"></p>
            <p v-else v-html="$t('plugin.domain_unlimit.description')"></p>
            <p v-if="activePlugin.plugin.balance_domains != -1" v-html="$t('plugin.balance_domains.description', activePlugin.plugin)"></p>
            <p v-else v-html="$t('plugin.balance_domains_unlimit.description', activePlugin.plugin)"></p>
        </b-modal>
    </div>
</template>
<script>
import filters from '@helpers/filters';
import {pluginMethods, privatePluginMethods} from "@storage";
import VueFormJsonSchema from "vue-form-json-schema/dist/vue-form-json-schema.esm.js";

export default {
	name: "BaseCopyright",
    filters,
    props: {
        domainData: Object,
        apiType: String
    },    
    components: {
        'dynamicForm': () => import('./dynamic-form.vue'),
        "vue-form-json-schema": VueFormJsonSchema,
    },
    watch: {
        domainData: function(domain) {           
            this.domain  = Object.assign(this.domain, domain);
        }       
    },
    methods: {          
        openPluginPopup(plugin) {
            this.activePlugin.plugin = plugin;
            this.activePlugin.show = true;
        },
        saveActivePluginConfig() {
            //this.cancelActivePluginConfig();
            this.$emit('change');
        },
        cancelActivePluginConfig() {
            this.configModal.show = false;
            this.activePluginConfiguration = {
                active_plugin: {}
            };
        },
        removePluginReference(plugin) {
            this.loadedItem = plugin.id;
            this.removeReference({
                domain_id: this.domain.id,
                plugin_id: plugin.id
            }).then(e => {
                this.$emit('change');
                this.loadedItem = null;
            });
        },
        confirmOk() {
            this[this.confirmModal.event].call(this, this.confirmModal.data);
            this.confirmModal = {
                show: false
            }
        },
        onUpdateDomainPlugin(plugin) {
            this.loadedItem = plugin.id;
            this.updateReference({
                data: {
                    active: plugin.active,
                    plugin_version: 'last'
                },
                plugin_id: plugin.id, domain_id: this.domain.id
            }).finally(()=> {
                this.loadedItem = null;
                this.$emit('change');
            });
        },
        configPlugin(plugin) {
            //console.log(plugin)
            this.activePluginConfiguration = plugin;
            this.configModal.show = true;
        },
        removePluginFromDomain(plugin) {
            //console.log(JSON.stringify(this.confirmModal));
            this.confirmModal.data = plugin;
            this.confirmModal.event = 'removePluginReference';
            this.confirmModal.message = this.$t('plugin.messages.removePluginFromDomain', this.domain);
            this.confirmModal.title = this.$t('plugin.messages.removePluginFromDomainTitle');
            this.confirmModal.show = true;
        },
        statusSwitcher(plugin, active) {
            this.loadedItem = plugin.id;
            this.updateReference({
                data: {
                    active
                },
                plugin_id: plugin.id, domain_id: this.domain.id
            }).finally(()=> {
                this.loadedItem = null;
                this.$emit('change');
            });
        }
    },
    beforeMount() {
        if(this.apiType == 'private') {
           Object.assign(this, privatePluginMethods);
        } else {
            Object.assign(this, pluginMethods);
        }   

        this.domain = Object.assign({}, this.domainData);
       
    },
    computed: {
        activePluginVersion() {
            //console.log(this.activePluginConfiguration.active_plugin)
            return this.activePluginConfiguration.plugin_version
        }
    },
    data() {
        return {         
            activePlugin: {
                show: false,
                plugin: {}
            },
            activePluginConfiguration: {
                active_plugin: {}
            },
            configModal: {
                show: false,
                title: this.$t('plugin.config.title')
            },
            domain: {},
            loaded: true,
            loadedItem: null,
            hasError: null,
            confirmModal: {
                show: false
            }
        }
    }
}
</script>
