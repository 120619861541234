<template>
	<footer class="app-main-footer">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-6 cell">										
					<h2>{{$t('footer.contacts')}}</h2>
					<address class="app-footer-address">
						<b>{{address}}</b> <br>
						{{$t('footer.mailbox')}}: <b>{{mailbox}}</b> <br>
						{{$t('footer.phone_number')}}: <a href="#" role="button" @click.prevent="toShowElement('phoneNumberDisplayed')" v-if="!phoneNumberDisplayed">{{$t('footer.click_to_display_phone_number')}}</a> <a  ref="phoneNumberDisplayed" v-if="phoneNumberDisplayed" :href="'tel:' + phoneNumber"><b>{{phoneNumber}}</b></a> <br> 
						{{$t('footer.email')}}: <a href="#" role="button" @click.prevent="toShowElement('emailDisplayed')" v-if="!emailDisplayed">{{$t('footer.click_to_display_email')}}</a> <a ref="emailDisplayed" v-if="emailDisplayed" :href="'mailto:' + email"><b>{{email}}</b></a>
					</address>					
				</div>
				<div class="col-lg-5 col-md-6 cell">
					<h2 v-text="$t('footer.site_navigation')"></h2>
					<ul class="app-footer-menu">
						<li v-for="item in navbar_links" v-bind:key="item.slug">
							<router-link :to="setURL(item.url)" :class="{active:activeURL(item.url, item.slug)}">{{$t('navbar.' + item.slug)}}</router-link>
						</li>						
						<li>
							<router-link :to="'/terms-of-use'|url" :class="{active: routeName == 'terms_of_use'}">{{$t('pages.terms_of_use.title')}}</router-link>							
						</li>
						<li>							
							<router-link :to="'/cookie-policy'|url" :class="{active: routeName == 'cookie_policy'}">{{$t('pages.cookie_policy.title')}}</router-link>
						</li>
						<li>					
							<router-link :to="'/privacy-policy'|url" :class="{active: routeName == 'privacy_policy'}">{{$t('pages.privacy_policy.title')}}</router-link>
						</li>
						<li>							
							<router-link :to="'/accessibility-statement'|url" :class="{active: routeName == 'accessibility_statement'}">{{$t('pages.accessibility_statement.title')}}</router-link>
						</li>			
						<li>							
							<router-link :to="'/about'|url" :class="{active: routeName == 'about'}">{{$t('pages.about.title')}}</router-link>
						</li>			
					</ul>
				</div>
				<div class="col-lg-3 col-md-12 cell">
					<div class="app-social-btns">
						<h2 :id="'we_in_social' + _uid" v-text="$t('footer.we_in_social_networks')"></h2>
						<nav :aria-labelledby="'we_in_social' + _uid">
							<ul>
								<li>
									<a :href="facebookUrl">
										<svg class="ungic-icon" role="img" :aria-labelledby="'un_3yv65vbzb' + _uid" width="2em" height="2em">
											<title :id="'un_3yv65vbzb' + _uid">Facebook</title>
											<use href="#svg-icon-social_facebook"></use>
										</svg>
									</a>
								</li>
								<li>
									<a :href="linkedinUrl">
										<svg class="ungic-icon" role="img" :aria-labelledby="'un_3yv65v34b' + _uid" width="2em" height="2em">
											<title :id="'un_3yv65v34b' + _uid">Linkedin</title>
											<use href="#svg-icon-social_linkedin"></use>
										</svg>
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>	
			<ul class="row app-footer-logos">
				<li>
					<router-link :to="'/'|url" class="logo">
						<svg role="img" :aria-labelledby="'un_fy99uvwby' + _uid" width="2em" class="ungic-icon">
							<title :id="'un_fy99uvwby' + _uid">Accessibility Zone</title>
							<use href="#svg-icon-acb_logo"></use>
						</svg>
					</router-link>		
				</li>				
				<li>
					<a href="https://webto.pro" target="_blank" class="logo">
						<svg role="img" :aria-labelledby="'un_fy99uvwby2' + _uid" width="3em" class="ungic-icon">
							<title :id="'un_fy99uvwby2' + _uid">WebTo.Pro</title>
							<use href="#svg-icon-wtp_logo"></use>
						</svg>
						<span class="sr-only">{{$t('target_link')}}</span>		
					</a>
				</li>
				<li>
					<a href="https://unbywyd.com" target="_blank" class="logo">
					<svg role="img" :aria-labelledby="'un_fy99uvwby3' + _uid" width="3em" class="ungic-icon">
						<title :id="'un_fy99uvwby3' + _uid">unbywyd</title>
						<use href="#svg-icon-unbywyd"></use>
					</svg>							
					<span class="sr-only">{{$t('target_link')}}</span>		
				</a>
				</li>
				<li>
					<a href="https://ungic.com" target="_blank" class="logo">
					<svg role="img" :aria-labelledby="'un_fy99uvwby4' + _uid" width="3em" class="ungic-icon">
						<title :id="'un_fy99uvwby4' + _uid">ungic</title>
						<use href="#svg-icon-ungic"></use>
					</svg>							
					<span class="sr-only">{{$t('target_link')}}</span>		
				</a>
				</li>
			</ul>	
		</div>
		<div class="app-copyryght-box">
			<div class="container">
				<i18n path="copyright.message" tag="p">
					<template v-slot:url>
						<a :href="'/'|url">Accessibility Zone</a>
					</template>
					<template v-slot:developed>
						<a :href="'https://webto.pro'|url" target="_blank">WebTo.Pro <span class="sr-only">{{$t('target_link')}}</span></a>
					</template>
					<template v-slot:year>
						{{year}}
					</template>
				</i18n>
			</div>							
		</div>
		<cookiesConfirmation></cookiesConfirmation>
	</footer>
</template>
<script>
import navbar_links from '@src/static_api/navbar';
import filters from '@helpers/filters';
import cookiesConfirmation from '@components/cookies-confirmation'; 

export default {
	name: "BaseCopyright",
	components: {
		cookiesConfirmation
	},
	data: function() {
		return {
			activeEl: '',
			navbar_links,
			phoneNumberDisplayed: false,
			emailDisplayed: false
		}
	},
	filters,
	mounted: function() {
		this.$emit('mounted');
	},
	methods: {
		toShowElement(key) {
			this[key] = true;
			this.$nextTick(() => {
				this.$refs[key].focus();
			});
		},
		setURL: function(url) {
			url = url.toString();
			if (this.isHome && /^\/#/.test(url)) {
				url = url.slice(1, url.length);
			}
			if(!/^http/.test(url)) {
				url = filters.url(url);
			}
			return url;
		},
		activeURL: function(url, slug) {
			let route = this.$route;
			let hasHash = () => route.hash && /^#/.test(route.hash);
			if ((url == '' || url == '/') && !hasHash()) {
				this.activeEl = slug;
				return this.isHome
			}
			if (route.hash && /#/.test(url) && new RegExp(route.hash + '$', 'igm').test(url)) {
				this.activeEl = slug;
				return true;
			}
			let res = this.$route.matched.some((route) => route.path.indexOf(url) != -1);
			if (res && (url == '' || url == '/') && hasHash()) {
				return false
			}
			if (res) {
				this.activeEl = slug;
			}
			return res;
		}
	},
	computed: {
		isHome: function() {
			return this.$route.matched.some((route) => route.meta.name == 'home' || route.meta.name == 'index');
		},
		routeName: function() {
			return this.$route.name || this.$route.meta.name
		},
		email: function() {
			return this.$store.state.settings.supportEmail;
		},
		mailbox: function() {
			return this.$store.state.settings.mailbox;
		},
		phoneNumber: function() {
			return this.$store.state.settings.phoneNumber;
		},
		facebookUrl: function() {
			return this.$store.state.settings.facebookUrl;
		},
		linkedinUrl: function() {
			return this.$store.state.settings.linkedinUrl;
		},
		year: function() {
			return this.$store.state.settings.yearCreation;
		},
		address: function() {
			return this.$store.state.settings.address
		}
	}
}
</script>