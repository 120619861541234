import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@locales/en'
//import {api} from '@api/index';
import store from '@state/store';
import dayjs from "dayjs";
var calendar = require('dayjs/plugin/calendar');
dayjs.extend(calendar);

import config from "@config";

let isLangSupported = function(lang) {
  let langs = store.getters['languages/supportedLanguageCodes'];
  return langs.includes(lang);
}

Vue.use(VueI18n);
const defaultLanguage = config.defaultLanguage;

//let defaultMessages
let messages = {};
messages[defaultLanguage] = en;
export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: defaultLanguage,
  messages
});

const loadedLanguages = [defaultLanguage]; 

export function routerHandler(router) {
  router.beforeEach((routeTo, routeFrom, next) => {
    let currentLanguage = store.getters['languages/currentLanguage'];
    
 
    if (routeTo.params.lang === routeFrom.params.lang && routeTo.params.lang) {
      next();
      return
    }
    let lang = routeTo.params.lang;
    if(isLangSupported(lang)) {
      loadLanguageAsync(lang).then(() => next());
      return
    }

    return next({
      path: '/'+ currentLanguage + (routeTo.redirectedFrom || routeTo.fullPath),
      replace: true
    });
  });
}
export function setI18nLanguage(lang) {
  i18n.locale = lang;
  store.dispatch('languages/language', lang);
  //api.defaults.headers.common['X-LANG'] = lang;
  dayjs.locale(lang);

  document.querySelector('html').setAttribute('lang', lang);
  if (['he', 'ar'].indexOf(lang) != -1) {
    document.querySelector('html').setAttribute('dir', 'rtl');
  } else {
    document.querySelector('html').setAttribute('dir', 'ltr');
  }
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language  //
  if (!isLangSupported(lang)) {
    return Promise.resolve();
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import( /* webpackChunkName: "lang-[request]" */ `@locales/${lang}.json`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}