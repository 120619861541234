<template>
	<div class="app-field field-group">
		<label :for="'app-field-' + _uid">{{$t(label) | capitalize}}<b v-if="required" class="app-field-required-sym">* <span class="sr-only">{{$t('field_required')}}</span></b></label>
		<span>
			<input :name="name" v-focus="focus" :disabled="disabled" :type="type" ref="input" :value="storage" :required="required" :aria-invalid="aria_invalid ? 'true': 'false' " :aria-labelledby="error ? ('app-field-alert-' + _uid) : false" @input="inputEvent" :placeholder="$t(label) | capitalize" @change="changeEvent" class="app-form-control form-control" :class=" {'is-valid': valid, 'is-invalid': isInvalid, 'not-empty': storage && storage.length}" :id="'app-field-' + _uid"><i aria-hidden="true"></i>
		</span>
		<transition name="fade">
			<p role="alert" :id="'app-field-alert-' + _uid" class="invalid-feedback" v-if="isInvalid" v-text="error"></p>
		</transition>
	</div>
</template>
<script>
import filters from '@helpers/filters';
export default {
	name: 'field',
	filters,
	props: {
		focus: Boolean,
		invalid: String,
		placeholder: String,
		label: String,
		value: String,
		type: String,
		name: String,
		disabled: Boolean,
		required: {
			type: Boolean,
			default: true
		},
		validator: {
			type: Function,
			default: function() {
				return
			}
		}
	},
	watch: {
		value: function(val) {
			if (this.storage != val) {
				this.storage = val;
			}
		},
		invalid: function(error) {
			this.error = error
		}
	},
	created: function() {
		this.storage = this.value;
		this.error = this.invalid;
	},
	mounted: function() {
		this.changeEvent(this.$refs.input.value, true);
	},
	methods: {
		clear: function() {
			this.changeEvent('');
		},
		inputEvent: function(e) {
			this.changeEvent(e);
		},
		changeEvent: function(e, skipEvents) {
			let value = 'string' == typeof e ? e : e.currentTarget.value;
			this.storage = value;
			let error = value === '' ? false : this.validator(this.name, value);
			if (error) {
				this.error = error;
			} else {
				this.error = false
			}
			if(!skipEvents) {
				if(value.trim() == '' && this.required || this.error) {
					this.$emit('invalid', error);
				} else {
					this.$emit('valid');
				}
				if(this.lastSended != value) {
					this.lastSended = value;
					this.$emit('change', this.name, error ? '' : value);
				}
			}
		}
	},
	computed: {
		isInvalid: function() {
			return this.storage && this.storage.length && this.error
		},
		valid: function() {
			return this.storage && this.storage.length && !this.error
		},
		aria_invalid: function() {
			if (this.required) {
				return this.invalid || !this.storage || (this.storage && !this.storage.length)
			} else {
				return this.invalid
			}
		}
	},
	data: function() {
		return {
			error: null,
			storage: ""
		}
	}
}
</script>