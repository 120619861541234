<template>
	<form class="app-contact-form" ref="form" id="app-contact-form" :aria-labelledby="'contact-us-' + _uid" :aria-describedby="(opened || !collapsed) ? 'cf-notifi' : null" @submit.prevent="submit">
		<div class="app-form-header">
			<BaseHeading :tag="headingTag" :id="'contact-us-' + _uid">{{$t('contact_us.title') | capitalize}}</BaseHeading>
			<p>
				<i18n path="contact_us.description">
					<span>
						<a href="#" class="td-none" :title="$t('click_to_display')" role="button" @click.prevent="address_showed = 1" v-if="!address_showed">{{$t('contact_us.email_address')|lowercase}}</a>
						<transition name="fade">
							<a v-if="address_showed" :href="'mailto:' + supportEmail" v-focus><b>{{supportEmail}}</b></a>
						</transition>
					</span>
					<span>
						<a href="#" class="td-none" :title="$t('click_to_display')" v-if="!opened && collapsed" role="button" @click.prevent="opened = true">{{$t('contact_us.show_form_link')}}</a>
						<span v-if="opened || !collapsed">{{$t('contact_us.show_form_link')}}</span>
					</span>
					<span>
						<a href="#" class="td-none" :title="$t('click_to_display')" role="button" @click.prevent="phone_showed = 1" v-if="!phone_showed">{{$t('contact_us.phone_number_message')|lowercase}}</a>
						<transition name="fade">
							<a v-if="phone_showed" :href="'tel:' + phoneNumber" v-focus><b>{{phoneNumber}}</b></a>
						</transition>
					</span>
					<br>
				</i18n>
			</p>
		</div>
		<div class="my-5" v-if="!collapsed"></div>
		<button class="btn btn-outline-secondary btn-mg" @click.prevent="opened = !opened" v-if="!opened && collapsed">{{$t('contact_us.show_form_btn') | capitalize}}</button>
		<transition name="fade">
			<div class="form" v-if="opened || !collapsed" @keyup.esc="opened = false" :role="collapsed ? 'dialog' : false" :aria-labelledby="collapsed ? 'contact-us-' + _uid : false">
				<BaseField :focus="true" :validator="fieldValidator" @change="changeFiled" type="text" name="fullname" label="contact_us.fullname" placeholder="fields.start_writing"></BaseField>
				<div class="row double">
					<div class="cell">
						<BaseField :validator="fieldValidator" @change="changeFiled" type="email" name="email" label="contact_us.email" placeholder="fields.start_writing"></BaseField>
					</div>
					<div class="cell">
						<BaseField :validator="fieldValidator" :required="false" @change="changeFiled" type="tel" name="tel" label="contact_us.tel" placeholder="fields.start_writing"></BaseField>
					</div>					
				</div>
				<baseTextarea label="contact_us.message" @change="changeFiled" :validator="fieldValidator" name="message" :value="message" placeholder="fields.start_writing"></baseTextarea>
				<div class="divider x3"></div>

				<div class="alert sm mg" :class="{
						'alert-danger': hasError,
						'alert-success': ((allow && !success) || success) && !hasError,
						'alert-warning': !allow && !hasError
				}" id="cf-notifi" ref="alert" role="alert" tabindex="-1">
					<span class="app-cf-required-fields" v-if="!allow && !hasError">{{$t('please_fill_in_the_following_fields') | capitalize}}: <span v-for="item in requiredFields" :key="item.id">
						<a href="#" @click.prevent="targetTo(item.id)">{{item.label}}</a>
					</span></span>
					<span v-if="allow && !success && !hasError">{{$t('form_successfully_completed') | capitalize}}</span>
					<span v-if="success && !hasError">{{$t('contact_us.success') | capitalize}}</span>
					<span v-if="hasError">{{$t('errors.something_went_wrong') | capitalize}}</span>
				</div>
	
				<div class="divider x3"></div>
				<button class="btn btn-secondary btn-md" @click.prevent="submit" :class="{loading: sendLoading, disabled: !allow, 'stretched': collapsed}" :aria-disabled="!allow" :tabindex="!allow ? '-1' : null" :disabled="sendLoading">{{$t('contact_us.send') | capitalize}}</button>
				<div class="divider hr"></div>
				<div class="grecaptcha">
					<svg class="ungic-icon" role="img" aria-hidden="true" width="2em" height="2em">
              		<use href="#svg-icon-shield"></use>
          		</svg>
					<recaptcha action="contact_us" ref="recaptcha" @ready="recaptcha = true"></recaptcha>
				</div>
			</div>
		</transition>
	</form>
</template>
<script>
import filters from '@helpers/filters';
import { api } from "@api";
export default {
	props: {
		headingTag: {
			type: String,
			default: 'h3'
		},
		collapsed: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		requiredFields() {
			let fields = [
				{
					label: this.$t('contact_us.fullname'),
					id: 'fullname',
					required: this.fullname.trim() == '' || !this.fullname
				},
				{
					label: this.$t('contact_us.email'),
					id: 'email',
					required: this.email.trim() == '' || !this.email
				},
				{
					label: this.$t('contact_us.message'),
					id: 'message',
					required: this.message.trim() == '' || !this.message
				},
			]
			return fields.filter(e => e.required);
		},
		language: function() {
			return this.$store.state.languages.currentLanguage
		},
		supportEmail: function() {
			return this.$store.state.settings.supportEmail
		},
		phoneNumber: function() {
			return this.$store.state.settings.phoneNumber
		},
		allow: function() {
			let v = f => 'string' == typeof f && f.trim() !== '';
			return v(this.email) && v(this.fullname) && v(this.message) && this.recaptcha
		}
	},
	mounted: function() {
		this.$emit('mounted');
	},
	components: {
		recaptcha: () => import("@components/recaptcha.vue")
	},
	methods: {
		targetTo(name) {
			this.$refs.form.querySelector('[name="'+name+'"]').focus();
		},
		submit: function() {
			if(!this.allow) {
				this.$refs.alert.focus();
				return
			}
			this.success = false;
			this.sendLoading = true;
			this.hasError = false;
			this.$refs.recaptcha.execute().then(token => {
				api.post('v1/website/contact-us', {
					email: this.email,
					recaptcha_token: token,
					phone: this.tel,
					name: this.fullname,
					description: this.message
				})
				.then((r) => {
					if (r.status >= 200 && r.status <= 204) {
						this.success = true;
					} else {
						this.hasError = true;
					}
				})
				.catch(() => this.hasError = true)
				.finally(() => this.sendLoading = false);
			});
		},
		changeFiled: function(name, value) {
			this[name] = value;
		},
		fieldValidator: function(key, value) {
			if (value.trim() == '') {
				return this.$t('errors.field_empty');
			}
			if (key == 'email') {
				if (!/^\S+@\S+\.\S+$/.test(value)) {
					return this.$t('errors.email_not_valid');
				}
			}
			if (key == 'tel') {
				if (!/^[\d+]+$/.test(value) || /^[\d+]+$/.test(value) && value.length < 9) {
					return this.$t('errors.phone_number_not_valid');
				}
			}
			if (key == 'message') {
				if (value.trim().length < 10) {
					return this.$t('errors.message_is_too_short')
				}
			}
		}
	},
	data: function() {
		return {
			recaptcha: false,
			opened: false,
			hasError: false,
			success: false,
			sendLoading: false,
			message: '',
			tel: '',
			email: '',
			fullname: '',
			address_showed: false,
			phone_showed: false
		}
	},
	filters
}
</script>