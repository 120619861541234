export const MODULE_ID = 'acsb-language';
import config from "@config";
import {auth, api, space, acsb, privateAcsb, privateAuth, privateSpace} from "@api";

let fromStorage = window.localStorage.getItem(MODULE_ID);
if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}

let defaultLanguage = config.defaultLanguage;

const getDefaultState = () => {
  return {
    supportedLanguages: [],
    currentLanguage: defaultLanguage
  }
}

export const state = getDefaultState();

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}

export const getters = {
  languages(state) {
    return state.supportedLanguages;
  },
  defaultLanguage() {
    let lang = window.navigator.language || window.navigator.userLanguage || defaultLanguage
    return lang.split('-')[0]
  },
  dir(state) {
    return ['he', 'ar'].indexOf(state.currentLanguage) == -1 ? 'ltr' : 'rtl';
  },
  currentLanguage(state) {
    return state.currentLanguage
  },
  supportedLanguageCodes(state) {
    return state.supportedLanguages.map(lang => lang.code);
  }
}

export const mutations = {
  language(state, langCode) {
    state.currentLanguage = langCode;
    saveState({
      langCode
    });
  },
  update(state, payload) {
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }
    saveState({
      langCode: state.currentLanguage
    });
  },
  currentRouteLanguage(state, lang) {
    if (lang != state.currentLanguage) {
      state.currentLanguage = lang
    }
    saveState({
      langCode: state.currentLanguage
    });
  },
  reset: function() {
    window.localStorage.removeItem(MODULE_ID);
    saveState(getDefaultState())
  }
}

export const actions = {
  init({ rootState, commit, state }) {
    let settings = rootState.settings;
    commit('update', {
      supportedLanguages: settings.supportedLanguages,
      currentLanguage: fromStorage.langCode ? fromStorage.langCode : settings.defaultLanguage
    })

    let setApiHeaders = (api, short) => {
      api.defaults.transformRequest = [function(data, headers) {
        headers.common['x-language'] = state.currentLanguage;
        if(short) {
          headers.common['x-lang'] = state.currentLanguage;
        }
        return data
      }, ...api.defaults.transformRequest];
    }
    
    setApiHeaders(api, true);
    
    setApiHeaders(acsb);
    setApiHeaders(space);
    setApiHeaders(auth);
    setApiHeaders(privateAcsb);
    setApiHeaders(privateAuth);
    setApiHeaders(privateSpace);

  },
  reset: function(context) {
    context.commit('reset');
    return context.dispatch('init');
  },
  currentRouteLanguage(ctx, lang) {
    ctx.commit('currentRouteLanguage', lang);
  },
  language(ctx, langCode) {
    let codes = ctx.getters.supportedLanguageCodes;
    if (codes.includes(langCode)) {
      ctx.commit('language', langCode);
    }
  }
}