import store from '@state/store'

let methods = {
  alt: function(value, meta) {
    return meta.title + ' | ' +  value;
  },
  url: function(url) {
    let lang = store.getters['languages/currentLanguage'];
    return '/' + lang + (/^\//.test(url) ? url : '/' + url);
  },
  capitalize: function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  lowercase: function(value) {
    if (!value) return ''
    value = value.toString()
    return value.toLowerCase();
  },
  mask: function(value) {
    let sp = value.length / 3;
    let s = () => {
      let outputs = '';
      for(let i=0; i< sp; i++) {
        outputs += '*';
      }
      return outputs;
    }
    return value.slice(0, sp) + s() + value.slice(sp * -1); 
  },
  emailMask: function(value) {
    let ar = value.split('@');
    return methods.mask(ar.shift()) + '@' + ar.shift();
  }
}
export default {
  ...methods
}