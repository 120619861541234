export default function(element, duration = 300, cb) {
    let elementY = window.pageYOffset + element.getBoundingClientRect().top;
    let startingY = window.pageYOffset;
    let diff = elementY - startingY;
    let start;
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        let time = timestamp - start;
        let percent = Math.min(time / duration, 1);
        let offset = () => {
            return window.innerHeight / 3;
        }
        window.scrollTo(0, startingY + (diff - offset()) * percent);
        if (time < duration) {
            window.requestAnimationFrame(step);
        } else {
            if(cb) {
                cb();
            }
        }
    });
}