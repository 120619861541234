export const MODULE_ID = 'acsb-services';

import { acsb } from "@api";
import dayjs from "dayjs";

let fromStorage = window.localStorage.getItem(MODULE_ID);
if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}

const getDefaultState = () => {
  return {
    app_id: '5e8b1e5e3ddfd972517cf300'
  }
}
export const state = getDefaultState();

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}

export const getters = {
 
}

export const mutations = {
  update: function(state, payload) {
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }
    saveState(state);
  },
  reset: function(state) {
    window.localStorage.removeItem(MODULE_ID);
    Object.assign(state, getDefaultState());
  }
}

export const actions = {
  init({ dispatch, state, getters, commit }) {
    acsb.defaults.transformRequest = [function(data, headers) {
      headers.common['x-app-id'] = state.app_id;       
      return data
    }, ...acsb.defaults.transformRequest];   
  },
  audit({commit}, payload) {
    return acsb.get('/api/audit', {
      params: {
        url: payload.url,
        standard: payload.standard
      }
    });
  }, 
  update: function(ctx, payload) {
    ctx.commit('update', payload)
  },
  reset: function(ctx) {
    ctx.commit('reset');
  }
}