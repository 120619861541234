<script>
import Layout from '@layouts/main.vue'

export default {
  page: function() {
    return this.$store.getters['settings/page'].call(this, 'loading')
  },
  components: {
    Layout
  }
}
</script>
<template>
  <Layout>
    <BasePageLoading></BasePageLoading>
  </Layout>
</template>