export const MODULE_ID = 'acsb-ref-space';

import { privateSpace } from "@api";
import dayjs from "dayjs";

let fromStorage = window.localStorage.getItem(MODULE_ID);
if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}

function domainConfig(id, config={}) {
  if(!config.headers) {
    config.headers = {}
  }
  config.headers['x-domain-id'] = id;
  return config;
}

const getDefaultState = () => {
  return {
    hasError: false,
    activeDomain: null,
    domains: [],
    role: null,
    amount_domains: null,
    active_plugins_limit: null,
    registration_date: null,
    amount_deactivated_domains: null,
    amount_active_domains: null,
    amount_plugins: null,
    domains: []
  }
}
export const state = getDefaultState();

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}

export const getters = {
  accountLoaded: function(state) {
    return !!state.role
  },
  storage: function(state) {
    return state;
  },
  get: function(state) {
    return key => state[key]
  },
  account: function(state) {
    let account = {
      role: state.role,
      amount_domains: state.amount_domains,
      active_plugins_limit: state.active_plugins_limit,
      amount_active_domains: state.amount_active_domains,
      amount_deactivated_domains: state.amount_deactivated_domains,
      created: dayjs(state.registration_date).calendar()
    }
    return account;
  }
}

function prepareDomain(domain) {
  if(domain.loading == undefined) {
    domain.loading = false;
  } 
  if(domain.checked == undefined) {
    domain.checked = false;
  }
  if(domain.updated == undefined) {
    domain.updated = false;
  }
  if(domain.blocked || !domain.verified) {
    domain.active = false;
  }   
  if(domain.updated_at) {
    domain.date_updated = dayjs(domain.updated_at).calendar(null, {sameElse: 'DD/MM/YYYY'});
  }
  if(domain.created_at) {
    domain.date_created = dayjs(domain.created_at).calendar(null, {sameElse: 'DD/MM/YYYY'});
  }
  return domain;
}

export const mutations = {
  update: function(state, payload) {
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }
    saveState(state);
  },
  loadingStatus: function(state, {id, status}) {
    let domain = state.domains.find(d => d.id == id);
    if(domain) {
      domain.loading = status;
    }
  },
  setDomain: function(state, domain) {
    let prev = state.domains.find(d => d.id == domain.id);
    if(prev) {
      prev = Object.assign(prev, prepareDomain(domain));
    } else {
      state.domains.push(prepareDomain(domain));    
    }
  },
  addDomain: function(state, domain) {
    state.domains.push(prepareDomain(domain));         
  },
  updateDomain: function(state, data) {
    let domain = state.domains.find(d => d.id == data.id);
    if(domain) {
      domain = prepareDomain(Object.assign(domain, data));
    }
  },
  resetDomains(state) {
    state.domains = [];
  },
  deleteDomain(state, id) {
    state.domains = state.domains.filter(e => e.id != id);          
  },
  setActiveDomain(state, data) {
    state.activeDomain = data;
  },
  reset: function(state) {
    window.localStorage.removeItem(MODULE_ID);
    Object.assign(state, getDefaultState());
  },
  loaded: function(state) {
    state.loading = false
  }
}

export const actions = {
  init({state}) {
    privateSpace.defaults.transformRequest = [function(data, headers) {      
      headers.common['x-ref-account-id'] = state.ref; 
      return data
    }, ...privateSpace.defaults.transformRequest];
  },
  getNotifications({commit}, data={}) {
    let config = data.config || {};
    return privateSpace.get('/account/notifis', config).then(e => {
      let notifications = e.data;     
      for(let notifi of notifications) {
        notifi.opened = false;
      }
      return notifications;
    });
  },
  getAmountNotifications() {
    return privateSpace.get('/account/notifis/amount');  
  },
  createNotification({commit}, data) {
    let config = data.config || {};
    return privateSpace.post('admin/account/notifi', data.data, config);
  },
  auth({commit, dispatch}, account_id) {
   
    commit('update', {
      ref: account_id
    });    
    return dispatch('logIn'); 
  },
  updateClientDomain({commit}, payload) {    
    commit('updateDomain', payload)
  },  
  fetchDomain({commit}, {id, config={}}) {    
    return new Promise((done, reject) => {
      privateSpace.get('account/domain', domainConfig(id, config)).then(e => {
        commit('setDomain', e.data);
        done(e.data);
      }).catch(reject);
    })
  },
  domainRegistration(ctx, {data={}, config={}}) {
    return new Promise((done, reject) => {
      privateSpace.post('account/domain/register', data, config).then(e=> {
        ctx.dispatch('fetchDomain', {id: e.data.id}).then(done).catch(reject);
      }).catch(reject);
    });
  },
  domainNameCheck(ctx, {domain, config={}}) {
    return new Promise(done => {
      if(!config.params) {
        config.params = {}
      }
      config.params.domain = domain;
      privateSpace.get('domain/exists', config).then(() => {
        done(true);
      }).catch(e => {
        done(false);
      }) 
    });
  },
  updateDomain({commit, dispatch}, {id, data={}, config={}}) {
    commit('loadingStatus', {
      id: (id || data.id), status: true
    });
    return privateSpace.put('/account/domain/edit', data, domainConfig((id || data.id), config)).then(e => {
      return dispatch('fetchDomain', {id, data, config});
    }).finally(() => {
      commit('loadingStatus', {
        id: (id || data.id), status: false
      });
    });
  },
  getConnectCode(ctx, data) {
    return  privateSpace.get('connect_code', domainConfig(data.domain_id, data.config || {}));
  },
  deleteDomain({commit}, {id, data={}, config={}}) {
    commit('loadingStatus', {id, status: true});
    return privateSpace.delete('/account/domain', domainConfig(id, config)).then(e => {
      commit('deleteDomain', id);
    });
  },  
  deleteDomains({commit}, {domains, data={}}) {
    data.domains = domains;
    for(let id of domains) {
      commit('loadingStatus', {id, status: true});
    }
    return privateSpace.delete('/account/domains', {data}).then(e => {
      for(let id of domains) {
        commit('deleteDomain', id);
      }
    });
  },
  domainVerifyConfirm({commit, dispatch}, {id, data={}, config={}}) {
    commit('loadingStatus', {
      id, status: true
    });
    return new Promise((done, reject) => {
      privateSpace.put('/account/domain/confirm', data, domainConfig(id, config)).then(() => {
        dispatch('fetchDomain', {id}).then(done).catch(reject).finally(() => {
          commit('loadingStatus', {
            id, status: false
          });
        })
      }).catch((e) => {
        reject(e);
        commit('loadingStatus', {
          id, status: false
        });
      });
    });
  },
  updateDomainPlugins({commit}, {id, data={}, config={}}) {
    commit('loadingStatus', {
      id, status: true
    });
    return privateSpace.put('/account/domain/update', data, domainConfig(id, config)).then(e => {
      let domain = e.data; domain.updated = true;
      commit('updateDomain', domain);
    }).finally(() => {
      commit('loadingStatus', {
        id, status: false
      });
    });
  },
  setActiveDomain: function({commit}, {id, config}) {
    if(!config) {
      config = {
        headers: {}
      }
    }
    if(!config.headers) {
      config.headers = {}
    }
    config.headers['x-domain-id'] = id;
    return privateSpace.get("account/domain", config).then(e => {
      let obj = prepareDomain(e.data);
      commit('setActiveDomain', obj);
      return obj;
    });
  },
  getDomains: function({commit}, {config}) {
    return privateSpace.get("account/domains/list", config).then(e => {
      let domains = e.data;
      commit('resetDomains');
      for(let domain of domains) {
        commit('addDomain', domain);
      }
      return e.data;
    });
  },
  logIn: function(ctx) {
    return new Promise(done => {
      privateSpace.get('/account/statistic').then(e => {
        ctx.commit('update', e.data);
        done(e.data);
      }).catch(e => {
        if(e.response.data.message == "Account is not registered in the application") {          
          return ctx.dispatch('registration').then(() => {
            ctx.dispatch('account');
          });
        }
        ctx.commit('update', {
          hasError: e
        });
        done(false);
      });
    });
  },
  update: function(ctx, payload) {
    ctx.commit('update', payload)
  },
  reset: function(ctx) {
    ctx.commit('reset');
  }
}