<template>
	<div>
		<label :for="'app-checkbox-' + _uid" class="app-checkbox">
			<input :disabled="disabled" :checked="checked" :required="required" @change="changeEvent" type="checkbox" :id="'app-checkbox-' + _uid" :name="name">
			<span>
				<i aria-hidden="true">
					<svg class="ungic-icon" role="img" aria-hidden="true" width="2em" height="2em">
						<use href="#svg-icon-tick" /></svg>
				</i>
				<span>{{theLabel}}<slot></slot><b v-if="required" class="app-field-required-sym">* <span class="sr-only">{{$t('field_required')}}</span></b></span>
			</span>
		</label>
		<p role="alert" :id="'app-field-alert-' + _uid" class="valid-feedback" v-if="error" v-text="error"></p>
	</div>
</template>
<script>
import filters from '@helpers/filters';
export default {
	name: 'checkbox',
	filters,
	props: {
		checked: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: String,
		required: {
			type: Boolean,
			default: true
		},
		validator: {
			type: Function,
			default: function() {
				return
			}
		}
	},
	mounted: function() {
		this.status = this.checked;
	},
	computed: {
		theLabel: function() {
			return filters.capitalize(this.$t(this.label))
		}
	},
	methods: {
		changeEvent: function(e) {
			let checked = e.currentTarget.checked;
			this.status = checked;
			let error = this.validator(this.name, checked);
			this.$emit('change', this.name, checked);
			if (error) {
				this.error = error;
			}
		}
	},
	data: function() {
		return {
			error: null
		}
	}
}
</script>