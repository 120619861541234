<script>
    import 'codemirror/lib/codemirror.css';
    import "codemirror/mode/javascript/javascript";    
    import "codemirror/mode/css/css";    
    import "codemirror/mode/xml/xml";    
    import "codemirror/mode/htmlmixed/htmlmixed";    
    import { codemirror } from 'vue-codemirror';
    import 'codemirror/theme/dracula.css';
    export default {
        components: {
            codemirror
        },
        props: {
            mode: String,
            value: String,
            readOnly: false,
            model: String
        },
        methods: {
            onInput(e) {
               this.$emit('change', e);
            }
        }
    }
</script>
<template>
   <codemirror @input="onInput" :value="value" :options="{mode, lineWrapping: true,  viewportMargin: Infinity, readOnly, theme: 'dracula', styleActiveLine: true, tabSize: 4, lineNumbers: true,  highlightDifferences: true}"></codemirror>
</template>