<template>
  <div class="form-group">
      <label :for="'app-field-' + _uid">{{$t(label) | capitalize}}<b v-if="required" class="app-field-required-sym">* <span class="sr-only">{{$t('field_required')}}</span></b></label>
      <textarea class="form-control" :value="storage" :name="name" :required="required" :aria-invalid="aria_invalid ? 'true': 'false' " :aria-labelledby="error ? ('app-field-alert-' + _uid) : false" @input="inputEvent" :placeholder="$t(label) | capitalize" @change="changeEvent" cols="30" rows="5" ref="input" :class="{'is-valid': valid, 'is-invalid': invalid, 'not-empty': storage && storage.length}" :id="'app-field-' + _uid"></textarea>
      <p role="alert" :id="'app-field-alert-' + _uid" class="invalid-feedback" v-if="invalid" v-text="error"></p>
  </div>
</template>
<script>
	import filters from '@helpers/filters';
	export default {
		name: 'field',
		filters,
		props: {
			placeholder: String,
			label: String,
			value: String,
			type: String,
			name: String,
			required: {
				type: Boolean,
				default: true
			},
			validator: {
				type: Function,
				default: function() {
					return
				}
			}
		},
		watch: {
			value: function(val) {
				if(this.storage != val) {
					this.storage = val;
				}
			}
		},
		created: function() {
			this.storage = this.value;
		},
		mounted: function() {
			this.changeEvent(this.$refs.input.value, true);
		},
		methods: {
			clear: function() {
				this.changeEvent('');
			},
			inputEvent: function(e) {
				this.changeEvent(e);
			},
			changeEvent: function(e, skipEvents) {
				let value = 'string' == typeof e ? e : e.currentTarget.value;
				this.storage = value;
				let error = value === '' ? false : this.validator(this.name, value);
				if (error) {
					this.error = error;
				} else {
					this.error = false
				}
				if(!skipEvents) {
					if(value.trim() == '' && this.required || this.error) {
						this.$emit('invalid', error);
					} else {
						this.$emit('valid');
					}
					if(this.lastSended != value) {
						this.lastSended = value;
						this.$emit('change', this.name, error ? '' : value);
					}
				}
			}
		},
		computed: {
			invalid: function() {
				return this.storage && this.storage.length && this.error
			},
			valid: function() {
				return this.storage && this.storage.length && !this.error
			},
			aria_invalid: function() {
				if(this.required) {
					return this.invalid || !this.storage || (this.storage && !this.storage.length)
				} else {
					return this.invalid
				}
			}
		},
		data: function() {
			return {
				error: null,
				storage: ""
			}
		}
	}
</script>