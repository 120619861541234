import axios from 'axios';
import config from '@config';


export const api = axios.create({
	baseURL: config.baseUrl
});

export const auth = axios.create({
	baseURL: config.authBaseUrl
});

export const space = axios.create({
	baseURL: config.spaceAPI
});

export const acsb = axios.create({
	baseURL: config.apiURL
});

export const privateAuth = axios.create({
	baseURL: config.authBaseUrl
});

export const privateSpace = axios.create({
	baseURL: config.spaceAPI
});

export const privateAcsb = axios.create({
	baseURL: config.apiURL
});