<script>
import {checkVisibleEls} from "@helpers/common";

export default {
	name: 'BaseLazyImg',
	inheritAttrs: false,
	props: {
		width: {
			type: Number
		},
		height: {
			type: Number
		},
		src: {
			type: String
		},
		wrapTag: {
			type: String,
			default: 'div'
		},
		iblock: {
			type: Boolean,
			default: false
		}
	},
	data: function() {
		return {
			loaded: false,
			inited: false
		}
	},
	methods: {
		isVisible: function() {
			if(!this.inited) {
				this.inited = true;
			}
			this.$refs.img.setAttribute('srcset', this.src);
		},
		test: function(e) {
			e.target.src = this.src;
		},
		onload: function() {
			if(this.inited) {
				this.loaded = true;
			}
		}
	},
	mounted: function() {
		checkVisibleEls();
	},
	render: function(c) {
		return c(this.wrapTag,
			{
				on: {
					'~visible': this.isVisible
				},
				class: {
					'img-lazy-loading': !this.loaded,
					'img-lazy-wrapper': true,
					'img-lazy-inited': this.inited,
					'img-lazy-iblock': this.iblock
				}
			},
			[c('img', {
				ref: 'img',
				on: {
					click: this.test,
					load: this.onload
				},
				attrs: Object.assign({
					srcset: this.placeholderSrc,
					decoding: "async",
					width: this.width,
					height: this.height,
					src: this.src
				}, this.$attrs)
			})]);
	},
	computed: {
		placeholderSrc() {
			return '/img/1px.png';
		}
	}
}
</script>