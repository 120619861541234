<script>
import { authMethods } from "@storage";
import config from '@config';

export default {
  mounted() {
    let script = document.createElement("script");
    script.src = config.ungicAuthUrl;
    script.id  = 'ungic-auth';
    document.addEventListener("ungicAuthReady", this.ungicAuthReady);
    document.querySelector("head").appendChild(script);    
  },
  beforeDestroy() {
    document.removeEventListener("ungicAuthReady", this.ungicAuthReady);
    document.getElementById('ungic-auth').remove();
    if(window.ungicAuth) {
      window.ungicAuth.off("load", this.ready);  
      window.ungicAuth.off("response", this.response);
    }
  },
  methods: {
    loaded() {
      this.options.container = this.$refs.container;
      
      window.ungicAuth.on("response", this.response);
      if (this.$store.state.auth.logout) {
        window.ungicAuth.logout();
      }
      window.ungicAuth.apiData().then((e) => {
        this.$store.commit("auth/update", {
          auth: e,
        });
        window.ungicAuth.init(this.options);
        this.inited = true;
      });
    },
    response(e) {   
      if (e.account) {
        this.logIn(e).then(() => {
          if(/logout/.test(this.$route.query.redirectFrom)) {
            return this.$router.push({ name: this.pageAfterLogin });
          }
          this.$router.push(
            this.$route.query.redirectFrom || { name: this.pageAfterLogin }
          );
        });
      }
    },
    ready() {
      window.ungicAuth.login();
      this.formReady = true;      
    },
    ungicAuthReady() {
      this.loaded(window.ungicAuth);
      window.ungicAuth.on("load", this.ready);  
    },
    ...authMethods,
  },
  props: {
    ID: {
      type: String,
      default: "_" + Math.random().toString(36).substr(2, 9),
    },
  },
  computed: {
      pageAfterLogin: function() {
          return this.$store.state.settings.pageAfterLogin
      }
  },
  data() {  
    return {    
      formReady: false,
      id: this.ID,
      inited: false,
      options: {
        language: this.$store.state.languages.currentLanguage,
        primaryColor: this.$store.getters['settings/primaryColor']
      }
    };
  },
};
</script>
<template>
    <div>
        <div class="app-loading" v-if="!formReady">          
            <b-spinner label="Loading" style="width: 3em; height: 3em;" variant="light"></b-spinner>        
        </div>
        <h1 class="sr-only" v-html="$t('authentication')"></h1>
        <div :id="id" ref="container"></div>
    </div>
</template>