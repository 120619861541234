<script>
import filters from "@helpers/filters";
import {spaceMethods, spaceComputed} from "@storage";

export default {
  filters,
  props: {
      domainVerifyModel: Object
  },
  data() {
      return {
        modalError: {
            show: false,
            message: '',
            title: ''
        },
        show: false,
        domainData: {},
        loading: true,
        loadings: {
            confirmDomain: false
        },
        errors: {
            domainVerify: null
        },
        token: null
      }
  },
  watch: {
      domainVerifyModel(status) {
          if('object' == typeof(status) && Object.keys(status).length) {
            this.domainData = status; 
          }
      },
      domainData(status) {       
          if(!status || 'object' == typeof(status) && !Object.keys(status).length) {
              this.onCancel();
          } else if('object' == typeof(status) && Object.keys(status).length) {            
              this.onVerify();
          }
      }
  },
  beforeMount() {
    this.domainData = this.domainVerifyModel;        
  },
  computed: {
    ...spaceComputed,
    currentDomainAccessTokenEncoded() {
      return encodeURIComponent(this.token)
    },
    switcher() {
        return !!Object.keys(this.domainData).length
    }
  }, 
  methods: {
    ...spaceMethods,
    onCancel() {
        this.show = false;
        this.$emit('cancel');
    },
    onVerify() {           
      let id = this.domainData.id;
      this.domainVerifyRequest({id}).then(data => {
        this.token = data.access_token;
       this.show = true;
      }).catch(e => {        
        this.pushError(
          this.$t('domain.verify.error.title'),
          e
        );
      }).finally(() => {
        this.loading = false;
      })
    },
    pushError(title, error) {  
        this.show = false;
        this.domainData = {};
        this.$emit('error', {
            title, error
        });
    },
    onConfirmVerify() {
      this.loadings.confirmDomain = true;
      this.loading = true;
      this.domainVerifyConfirm({id: this.domainData.id}).catch(e => {        
        let data = e.response.data;
        this.errors.domainVerify = data.message;
      }).finally(() => {
        this.loadings.confirmDomain = false;
         this.loading = false;         
      });
    }
  }
}
</script>

<template>
    <div>
        <b-modal id="domains-domain-verify" v-model="show" ref="domains-domain-verify" hide-footer :title="$t('domain.modals.verify.title')">
            <div v-if="!loading">
                <div v-if="!domainData.verified">
                    <p>{{$t('domain.modals.verify.desc')}}</p>
                    <ol>
                        <li><a download="ungic.confirm.txt" :href="'data:text/plain;charset=utf-8,' + currentDomainAccessTokenEncoded" 
                        class="btn btn-sm btn-outline-primary"
                        v-html="$t('domain.modals.verify.download_btn')"></a> <span v-html="$t('domain.modals.verify.step_1')"></span> <br> <br>
                        <b v-html="$t('domain.modals.verify.example')"></b>
                        <pre>
            +---httpdocs
            |   - index.php
            |   - ungic.confirm.txt
                        </pre>
                        <b-alert variant="info" show v-html="$t('domain.modals.verify.alert')"></b-alert>
                        </li>
                        <li>
                        <p v-html="$t('domain.modals.verify.step_2')"></p> <br>         
                        </li>
                    </ol>
                    <hr>
                    <b-alert variant="danger" show v-if="errors.domainVerify" dismissible v-html="errors.domainVerify"></b-alert>
                    <div class="text-center">
                        <b-btn variant="success" :disabled="loadings.confirmDomain" @click.prevent="onConfirmVerify">
                        <b-spinner class="align-middle" v-if="loadings.confirmDomain" :label="$t('loading.title')"></b-spinner>
                        <span v-html="$t('domain.modals.verify.check_btn')"></span>
                        </b-btn>
                        <b-btn variant="light" @click.prevent="domainData={}" v-html="$t('btns.cancel')"></b-btn>
                    </div>    
                    </div>
                    <div v-else>
                    <b-alert variant="success" show v-html="this.$t('domain.modals.verify.success_verified.message')"></b-alert>
                    <div class="text-center">
                        <b-btn variant="light" @click.prevent="onCancel()" v-html="$t('btns.cancel')"></b-btn>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">              
                <b-spinner :label="$t('loading.title')"></b-spinner>                
            </div>    
        </b-modal>        
    </div>
</template>