<template>
  <div id="app" class="app">    
    <RouterView :key="$route.fullPath" />
      <!-- тут могут находиться общие компоненты для любого раутинга  -->
    <div id="app-dynamic-area">
      <!-- место для модальных окон  -->
    </div>
    
  </div>
</template>
<script>
 
  export default {
   
    mounted: function() {
      let pushEvent = () => {
        setTimeout(function() {
          document.dispatchEvent(new Event("x-app-rendered"));
        }, 3000);
      }    
      if (!this.$route.meta.wait) {
        pushEvent();
      } else {
        let ready = () => {
          document.removeEventListener('page_ready', ready);
          document.dispatchEvent(new Event("x-app-rendered"));
        }
        document.addEventListener('page_ready', ready);
      }
    },
    methods: {
      hideOnBlur(e) {
        e.target.classList.remove('display');
      },
      showOnFocus(e) { 
        e.target.classList.add('display');
      }
    }
  }
</script>
