<script>
import scrollto from "@utils/scrollto"

import {globalTrapOutside,globalKeypress,checkVisibleEls} from "@helpers/common"
import {throttle} from "@helpers/utils";

function globalScrollEvent() {
	var doc = document.documentElement;
	var x = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
	var y = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	window.localStorage.setItem('app-last-scroll-position', `${x},${y}`);
	checkVisibleEls();
}

export default {
	mounted: function() {
		checkVisibleEls();
		window.addEventListener('scroll', throttle(globalScrollEvent, 32));
		window.addEventListener('keydown', globalKeypress);
		document.addEventListener('click', globalTrapOutside);
		document.addEventListener('focus', globalTrapOutside, true);

		window.addEventListener('online', () => {
			if (!this.$store.getters['settings/get']('online')) {
				window.location.reload();
			}
			this.$store.dispatch('settings/update', { prop: 'online', value: true });
		});
		window.addEventListener('offline', () => {
			this.$store.dispatch('settings/update', { prop: 'online', value: false });
		});
	},
	destroyed: function() {
		window.removeEventListener('keydown', globalKeypress);
		document.removeEventListener('click', globalTrapOutside);
		document.removeEventListener('focus', globalTrapOutside);
		window.removeEventListener('scroll', globalScrollEvent);
	},
	page: function() {
		return {
			titleTemplate(title) {
				title = typeof title === 'function' ? title(this.$store) : title;
				return title ? `${title} | ${this.$t('app_title')}` : this.$t('app_title')
			}
		}
	},
	beforeCreate: function() {
		// Обновили статус онлайн/офлайн сайта
		this.$store.dispatch('settings/update', { prop: 'online', value: window.navigator.onLine });
		// Обновили настройки притянул конфиг с сервера.
		this.$store.dispatch('settings/initialize').finally(async () => {
			// Если ещё что-то надо, и вызываем loaded, тем самым отметив, что страница готова целиком.
			this.$store.dispatch('settings/loaded');
		});
	},
	computed: {
		classNames: function() {			
			let classNames = {}
			let name = this.$route.name || this.$route.meta.name || 'noname';
			classNames['app-layout-' + name] = true;
			return classNames;
		},
		pageLoading: function() {
			return this.$store.getters['settings/loading'];
		}
	},
	updated: throttle(function() {
		this.$nextTick(function() {
			let route = this.$route;
			try {
				if (route.hash && this.$el.querySelector(route.hash)) {
					scrollto(this.$el.querySelector(route.hash), 300);
				}
			} catch (e) {
				console.log(e);
			}
		})
	}, 32)
}
</script>
<template>
	<div class="app-layout-container" :aria-hidden="$store.state.settings.hasModal">
		<slot name="header" />
		<div class="app-layout" :class="classNames">
			<slot />
		</div>
		<slot name="footer" />		
		<div class="app-loading" v-if="pageLoading">          
            <b-spinner label="Loading" style="width: 3em; height: 3em;" variant="primary"></b-spinner>        
        </div>
	</div>
</template>