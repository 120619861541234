import {privateAuth} from "@api";
export const MODULE_ID = 'acsb-ref-auth';
import dayjs from "dayjs";
let fromStorage = window.localStorage.getItem(MODULE_ID);

if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}


const getDefaultState = () => {
  return {
    ref: '',
    account: {}, 
    auth: {},
    access_token: null,
    refresh_token: null
  }
}

export const state =  Object.assign(getDefaultState(), fromStorage);

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}

export const getters = {
  account_id(state) {
    return state.account.id
  },
  loaded(state) {
    return !!state.account.id
  },
  account(state) {
 
    if(state.account.first_name && state.account.last_name) {
      state.account.full_name = state.account.first_name + ' ' + state.account.last_name;
    }
    if(state.account.created_at) {
      state.account.created = dayjs(state.account.created_at).calendar();
    }
    return state.account;
  }
}

export const mutations = {
  update(state, payload) { 
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }  
    saveState(state);
  },
  setRef(state, refId) {
    state.ref = refId;
  },
  reset: function(state) {
    window.localStorage.removeItem(MODULE_ID);
    let data = getDefaultState()  
    for(let key in data) {
      let val = data[key];
      state[key] = val;
    }    
    fromStorage = {}
    saveState(data);
  }
}

export const actions = {
  init({state}) {
    privateAuth.defaults.transformRequest = [function(data, headers) {      
      headers.common['x-ref-account-id'] = state.ref; 
      return data
    }, ...privateAuth.defaults.transformRequest];
  },
  auth({commit, dispatch}, account_id) {
    commit('update', {
      ref: account_id
    });    
    return dispatch('account'); 
  },
  update({commit}, payload) {
    commit('update', payload);
  },
  account({commit}) {
    return new Promise(res => {
      privateAuth.get('/account').then(e => {     
        commit('update', {
          account: e.data
        });
        res(e.data);   
      }).catch(() => {       
        commit('reset');
        res(false);
      });      
    });     
  }
}