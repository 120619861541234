import store from '@state/store';
let pages = store.getters['settings/get']('pages');
export default [
    {
        path: "",
        meta: Object.assign({
            wait: true,
            name: 'home'
        }, pages.home || {}),
        component: () => lazyLoadView(import('@views/home.vue')),
    },
    {
        path: "dashboard",
        meta: Object.assign({
            authRequired: true,
            name: 'dashboard',
            wait: true
        }, pages.dashboard || {}),
        component: () => lazyLoadView(import('@views/dashboard.vue')),
    },
    {
        path: 'signin',
        component: () => lazyLoadView(import('@views/login.vue')),
        meta: {
            name: 'signin',
            formOnly: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in              
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: store.state.settings.pageAfterLogin })
                } else {
                    // Continue to the login page
                    next()
                }
            }
        }
    },
    {
        path: 'login',
        component: () => lazyLoadView(import('@views/login.vue')),
        meta: {
            name: 'login',
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in              
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: store.state.settings.pageAfterLogin })
                } else {
                    // Continue to the login page
                    next()
                }
            }
        }
    },
    {
        path: 'logout',
        meta: {
            authRequired: true,
            name: 'logout',
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut').then(e => {
                    //next({name: 'login'});
                    //return
                    const authRequiredOnPreviousRoute = routeFrom.matched.some(
                        (route) => route.meta.authRequired
                    )
                    next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
                });
            },
        },
    },
    {
        path: "plugins",
        component: () => lazyLoadView(import('@views/plugins.vue')),
        meta: Object.assign({
            name: 'plugins_archive',
            //authAdminRequired: true,
        }, pages.plugins_archive || {})
    },
    {
        path: "admin/plugins",
        component: () => lazyLoadView(import('@views/admin/plugins.vue')),
        meta: Object.assign({
            name: 'admin_plugins',
            authAdminRequired: true,
        }, pages.plugins_archive || {})
    },
    {
        path: "plugins/:plugin_slug",
        component: () => lazyLoadView(import('@views/plugins/index.vue')),
        meta: Object.assign({
            name: 'single_plugin_page',
            wait: true
        }, pages.plugins_single || {}),
        children: [
            {
                path: "",
                component: () => lazyLoadView(import('@views/plugins/post.vue')),
                meta: Object.assign({
                    name: 'single_plugin_post'
                }, pages.plugin || {})
            }
        ]
    },    
    {
        path: "audit/:url",
        component: () => lazyLoadView(import('@views/audit.vue')),
        meta: Object.assign({
            name: 'audit'
        }, pages.audit || {})
    },
    {
        path: "profiles",
        component: () => lazyLoadView(import('@views/profiles.vue')),
        meta: Object.assign({
            name: 'profiles',
            authAdminRequired: true,
        }, pages.profiles || {})
    },
    {
        path: "siteurls_generator",
        component: () => lazyLoadView(import('@views/siteurls_generator.vue')),
        meta: Object.assign({
            name: 'siteurls_generator',
            authAdminRequired: true,
        }, pages.siteurls_generator || {})
    },
    {
        path: "profiles/:ref",
        component: () => lazyLoadView(import('@views/profiles/index.vue')),
        meta: Object.assign({
            name: 'private_profile',
            authAdminRequired: true,
            wait: true
        }, pages.profile_private || {}),
        children: [
            {
                path: "",
                component: () => lazyLoadView(import('@views/profiles/about.vue')),
                meta: Object.assign({
                    name: 'private_about_profile',
                    privateSpaceAuth: true,
                }, pages.profiles || {})
            },
            {
                path: "settings",
                component: () => lazyLoadView(import('@views/profiles/settings.vue')),
                meta: Object.assign({
                    name: 'private_settings_profile'
                }, pages.profiles || {})
            },
            {
                path: "domains",
                component: () => lazyLoadView(import('@views/profiles/domains.vue')),
                meta: Object.assign({
                    name: 'private_domains',
                    privateSpaceAuth: true
                }, pages.profiles || {})
            },
            {
                path: "notifications",
                component: () => lazyLoadView(import('@views/profiles/notifications.vue')),
                meta: Object.assign({
                    name: 'private_notifications',
                    privateSpaceAuth: true
                }, pages.profiles || {})
            },
            {
                path: 'domains/:domain_id',
                component: () => lazyLoadView(import('@views/profiles/domain.vue')),
                meta: Object.assign({
                    name: 'private_domain',
                    privateSpaceAuth: true
                }, pages.profile_domains || {})
            },
        ]
    },
    {
        path: "profile",
        component: () => lazyLoadView(import('@views/profile.vue')),
        meta: Object.assign({
            name: 'profile',
            authRequired: true,
        }, pages.profile || {}),
        children: [
            {
                path: "",
                name: 'profile_about',
                component: () => lazyLoadView(import('@views/profile/index.vue')),
                meta: Object.assign({
                    name: 'profile_about',
                    wait: true
                }, pages.profile_about || {})
            },
            {
                path: "settings",
                name: 'profile_settings',
                component: () => lazyLoadView(import('@views/profile/settings.vue')),
                meta: Object.assign({
                    name: 'profile_settings',
                    wait: true
                }, pages.profile_settings || {})
            },
            /*{
                path: "notifications",
                name: 'profile_notifications',  
                component: () => lazyLoadView(import('@views/profile/notifi.vue')),                
                meta: Object.assign({         
                    name: 'profile_notifications',         
                    wait: true
                }, pages.profile_notifi || {})
            },*/
            {
                path: "notifications/:notifi_id?",
                name: 'profile_notifi',
                component: () => lazyLoadView(import('@views/profile/notifi.vue')),
                meta: Object.assign({
                    name: 'profile_notifi',
                    wait: true
                }, pages.profile_notifi || {})
            }
        ]
    },
    {
        path: 'domains/',
        //name: 'domains',
        component: () => lazyLoadView(import('@views/domains.vue')),
        meta: Object.assign({
            name: 'domains',
            authRequired: true,
            wait: true
        }, pages.profile_domains || {})
    },
    {
        path: 'domains/:domain_id',
        //name: 'domain',
        component: () => lazyLoadView(import('@views/domain.vue')),
        meta: Object.assign({
            name: 'domain',
            authRequired: true,
            wait: true
        }, pages.profile_domains || {})
    },
    {
        path: 'me/domains/:domain_id',
        component: () => lazyLoadView(import('@views/me_domain.vue')),
        meta: Object.assign({
            name: 'me_by_domain',
            authRequired: true,
            wait: true
        }, pages.profile_domains || {})
    },
    {
        path: "contact-us",
        meta: Object.assign({
            name: 'contact_us',
            wait: true
        }, pages.contact_us || {}),
        component: () => lazyLoadView(import('@views/contact_us.vue')),
    },
    {
        path: 'unsubscribe/:id',
        meta: Object.assign({
            name: 'unsubscribe',
        }, pages.unsubscribe || {}),
        component: () => lazyLoadView(import('@views/unsubscribe.vue')),
    },
    {
        path: "terms-of-use",
        meta: Object.assign({
            name: "terms_of_use",
            wait: true
        }, pages.common),
        component: () => lazyLoadView(import('@views/page.vue'))
    },
    {
        path: "about",
        meta: Object.assign({
            name: "about",
            wait: true
        }, pages.common),
        component: () => lazyLoadView(import('@views/page.vue'))
    },
    {
        path: "cookie-policy",
        meta: Object.assign({
            name: "cookie_policy",
            wait: true
        }, pages.common),
        component: () => lazyLoadView(import('@views/page.vue'))
    },
    {
        path: "privacy-policy",
        meta: Object.assign({
            name: "privacy_policy",
            wait: true
        }, pages.common),
        component: () => lazyLoadView(import('@views/page.vue'))
    },
    {
        path: "accessibility-statement",
        meta: Object.assign({
            name: "accessibility_statement",
            wait: true
        }, pages.common),
        component: () => lazyLoadView(import('@views/page.vue'))
    },
    {
        path: '404',
        meta: Object.assign({
            wait: true,
            name: '404'
        }, pages.error404 || {}),
        component: require('@views/_404.vue').default,
    },
    {
        path: '403',
        meta: Object.assign({
            wait: true,
            name: '403'
        }, pages.error403 || {}),
        component: require('@views/_403.vue').default,
    },
    {
        path: '*',
        redirect: '404'
    }
]
function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        // A component to use while the component is loading.
        loading: require('@views/_loading.vue').default,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 400,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        error: require('@views/_timeout.vue').default,
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        timeout: 10000,
    })

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            // Transparently pass any props or children
            // to the view component.
            return h(AsyncHandler, data, children)
        },
    })
}