<template>
    <aside class="app-sidebar">
        <SidebarMenu :rtl="false" @toggle-collapse="onToggleCollapse" :collapsed="collapsed" :menu="menu" >
            <div slot="header">                
                <div class="app-sidebar-header">
                    <router-link :to="'dashboard'|url"><svg aria-hidden="true" width="2em" height="2em"><use xlink:href="#svg-icon-acb_logo"></use></svg>
                        <transition name="fade-in">
                            <span v-if="!isColapsed">{{appName}}</span>
                        </transition>
                    </router-link>
                </div>
            </div>
            <span slot="toggle-icon">          
                <span v-if="isRTL">
                    <b-icon icon="arrow-right-circle" :aria-label="$t('sidebar.close')" v-if="!isColapsed" height="1.6em" width="1.6em"></b-icon>
                    <b-icon icon="arrow-left-circle" :aria-label="$t('sidebar.open')" v-else height="1.6em" width="1.6em"></b-icon>                 
                </span>    
                <span v-else>
                    <b-icon icon="arrow-left-circle" :aria-label="$t('sidebar.close')" v-if="!isColapsed" height="1.6em" width="1.6em"></b-icon>
                    <b-icon icon="arrow-right-circle" :aria-label="$t('sidebar.open')" v-else height="1.6em" width="1.6em"></b-icon> 
                </span>             
            </span>
            <div slot="footer">
                 <b-nav vertical hidden v-if="!isColapsed" class="app-sidebar-footer-menu">
                    <b-nav-item class="text-center" :to="'logout'|url">{{$t('auth.logout')}}</b-nav-item>
                 </b-nav>
            </div>
            <span slot="dropdown-icon"><b-icon icon="chevron-down"></b-icon></span>
        </SidebarMenu>        
    </aside>
</template>
<script>
import sidebarLinks from '@src/static_api/sidebar';
import filters from '@helpers/filters';
import store from "@state/store";
import config from "@config";
import { SidebarMenu } from 'vue-sidebar-menu';


export default {
    name: 'baseSidebar',
    filters,
    components: {
        SidebarMenu
    },
    watch: {
        isColapsed: function(s) {
            this.checkMenu(s);           
        },
        collapsed: function(collapsed) {
            this.checkMenu(collapsed);
        }
    },
    beforeDestroy() {
        document.querySelector('body').classList.remove('app-sidebar-opened');
        document.querySelector('body').classList.remove('app-sidebar-closed');
    },
    computed: {
        appName() {
            return config.appName
        },
        account() {
            return this.$store.getters['auth/account']
        },
        isRTL() {
            return store.getters['languages/dir'] == 'rtl'
        },
        menu() {            
            let itemHandle = item => {
                if(item.url) {
                    item.href = {
                        path: filters.url(item.url)
                    }        
                }    
                if(item.icon) {
                    item.icon.attributes = {
                        'aria-label':  this.$t('sidebar.items.' + item.slug)        
                    }
                }
                item.title = this.$t('sidebar.items.' + item.slug);         
            }
            let itemsHandle = items => {
                for(let item of items) {
                    if(item.child) {
                        itemsHandle(item.child);
                    }
                    itemHandle(item);
                }
            }
            itemsHandle(sidebarLinks);
            return sidebarLinks;
        }
    },  
    mounted: function() {
        let collapsed = true;
        try {
            let prevSave = window.localStorage.getItem('app-sidebar-collapsed');  
            if(prevSave !== null) {
                collapsed = prevSave === 'true';
            }
        } catch(e) {

        }
        this.collapsed = collapsed;
        this.isColapsed = !!(this.collapsed);       
        this.checkMenu(this.collapsed);
    },
    methods: {
        checkMenu: function(s) {                    
            if(!s) {
                document.querySelector('body').classList.remove('app-sidebar-closed');
                document.querySelector('body').classList.add('app-sidebar-opened');
            } else {
                document.querySelector('body').classList.remove('app-sidebar-opened');
                document.querySelector('body').classList.add('app-sidebar-closed');
            }
        },
        onToggleCollapse(collapsed) {         
            this.isColapsed = collapsed;
            window.localStorage.setItem('app-sidebar-collapsed', collapsed ? 'true' : 'false'); 
            this.collapsed = collapsed;
            console.log('collapsed', this.collapsed);
        }
    },
    data: function() {
        return {
            collapsed: true,
            isColapsed: true
        }
    }
}
</script>