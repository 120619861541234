<template>
    <div>        
        <div class="text-center" v-if="!ready">
            <b-spinner :label="$t('loading.title')"></b-spinner>
        </div>
        <div v-if="hasError">
            <b-alert variant="danger" show v-html="hasError"></b-alert>
        </div>
       <div v-if="ready && !hasError">      
           <p>{{$t('plugins.picker.top_message')}}</p>      
            <b-alert variant="info" show v-if="!plugins.length">{{$t('plugins.messages.no_plugins')}}</b-alert>
            <div v-else >
                <div role="listbox" @keyup.down="onDown" @keyup.up="onUp" tabindex="0" :aria-label="$t('plugins.picker.label')" :aria-activedescendant="activeElement.id">
                    <div v-for="plugin in plugins" :key="plugin.id" class="mb-2">
                        <b-card no-body @click="selectOption(plugin)"                   
                        :aria-selected="activeElement.id == plugin.id" 
                        :tabindex="tabIndex(plugin)"
                        :id="'plugin_' + plugin.id"   
                        :ref="'plugin_' + plugin.id"                     
                        role="option" 
                        :aria-disabled="!plugin.enabled || pluginUsed(plugin.id) || !plugin.balance_domains" :class="classElement(plugin)" v-if="loadedItem!=plugin.id" >

                         <b-row no-gutters class="ai-center">
                             <b-col sm="12" md="3" class="p-2">
                                <b class="app-plugin-picker-name"><span class="plugin-name">{{plugin.name}}</span> <b-badge variant="info">{{plugin.last_version.version}}</b-badge></b>                              
                             </b-col>
                             <b-col sm="12" md="6">
                                <b-card-body>
                                    {{plugin.description}}
                                </b-card-body>
                             </b-col>
                             <b-col sm="12" md="3" class="p-2">

                                <span v-if="plugin.enabled && !pluginUsed(plugin.id)" :class="btnElementClass(plugin)">
                                    <span  v-if="activeElement.id == plugin.id">
                                        <span class="sr-only">{{$t('btns.selected')}}</span>
                                        <baseIcon id="tick" :presentation="true"></baseIcon>
                                    </span> 
                                                       
                                </span>
                                <div class="text-primary"  v-if="pluginUsed(plugin.id)">
                                     {{$t('plugin.messages.already_used')}}
                                </div>                      
                                <div class="text-danger"  v-if="!plugin.balance_domains && plugin.domains_limit && !pluginUsed(plugin.id)">
                                     {{$t('plugin.messages.balance_exhausted')}}
                                </div>
                                <div class="text-danger"  v-if="!plugin.balance_domains && !plugin.domains_limit">
                                     {{$t('plugin.messages.needs_upgrade')}}
                                </div>
                                <div class="text-primary"  v-if="!pluginUsed(plugin.id) && plugin.balance_domains && activeElement.id != plugin.id">
                                     {{$t('plugin.select')}}
                                </div>
                             </b-col>
                         </b-row>
                        </b-card>
                                        
                        <b-card v-else class="text-center">                  
                            <b-spinner :label="$t('loading.title')"></b-spinner>                 
                        </b-card>
                    </div>
                </div> 
                <hr>
               <div class="text-center">
                    <button class="btn btn-success" @click.prevent="submit" :disabled="!activeElement.id">{{$t('btns.add')}}</button>       
                    <button class="btn btn-light" @click.prevent="$emit('cancel')">{{$t('btns.cancel')}}</button>       
               </div>
            </div>        
       </div>
    </div>
</template>
<script>
import filters from '@helpers/filters';
import {pluginMethods, pluginComputed} from "@storage";
export default {
	name: "basePluginsPicker",
    filters,    
    props: {
        domainData: Object
    },
    beforeMount() {
        this.fetchPlugins().catch(e => {
            this.hasError = e.message;
        }).finally(()=> {
              this.ready = true;
        })
    },
    mounted() {
        console.log(this.plugins)
    },
    data() {
        return {
            hasError: null,
            ready: false,
            loadedItem: null,
            activeElement: {}
        }
    },
    watch: {
        activeElement(el) {
            if(el.id) {
                this.$nextTick(function () {
                   this.$el.querySelector('#plugin_' + el.id).focus();
                });
            }
        }
    },
    methods: {
        ...pluginMethods,
        tabIndex(plugin) {
            return this.activeElement.id == plugin.id ? 0 : -1
        },
        onDown(e) {
            if(!this.plugins.length) {
                return
            }
            if(this.activeElement) {
                let plugins = this.plugins.filter(el => el.enabled && !this.pluginUsed(el));
                let activePlugin = plugins.find(e => e.id == this.activeElement.id);
                let activeIndex = plugins.indexOf(activePlugin);
                let nextEl = plugins[activeIndex + 1];
                if(nextEl) {
                    this.activeElement = nextEl;
                }
            } else {
                this.activeElement = plugins[0];
            }
        },
        onUp(e) {
            if(!this.plugins.length) {
                return
            }
            if(this.activeElement) {
                let plugins = this.plugins.filter(el => el.enabled && !this.pluginUsed(el));
              
                let activePlugin = plugins.find(e => e.id == this.activeElement.id);
                let activeIndex = plugins.indexOf(activePlugin);
                let prevEl = plugins[activeIndex - 1];
                if(prevEl) {
                    this.activeElement = prevEl;
                }
            } else {
                this.activeElement = plugins[0];
            }
        },
        btnElementClass(plugin) {
            let data = {}
            
            if(!plugin.enabled || this.pluginUsed(plugin.id)) {
                data['text-dark'] = true;
                return data;
            }
            if(this.activeElement.id == plugin.id) {
                data['text-light'] = true;
            } else {
                data['text-primary'] = true;
            }
            return data;
        },
        submit() {
            if(this.activeElement.id) {
                this.$emit('submit', this.activeElement);
            }
        },
        pluginUsed(id) {
            return !!this.usedPlugins.find(e => e.id == id);
        },
        selectOption(plugin) {
            if(this.pluginUsed(plugin.id) || !plugin.balance_domains) {
                return
            }
            this.activeElement = plugin;
        },
        classElement(plugin) {
             let data = {
            
            }
            if(!this.pluginUsed(plugin.id) && plugin.balance_domains) {
                data['cursor-pointer'] = true;
            }
           
            if(this.activeElement.id == plugin.id) {           
                data["bg-success"] = true;             
                data["text-white"] = true;             
            }
            if(this.pluginUsed(plugin.id) || !plugin.balance_domains) {
                data['bg-light']= true;
                data['border-light']= true;
            }
            return data
        }
        
    },
    computed: {
        ...pluginComputed, 
        usedPlugins() {
            return this.domainData.plugins
        }
    }
}
</script>