export function throttle(func, delay) {
	var timer = 0;
	return function() {
		var context = this,
			args = [].slice.call(arguments);

		clearTimeout(timer);
		timer = setTimeout(function() {
			func.apply(context, args);
		}, delay);
	}
}