<template>
	<form class="app-cb" :id="'subscribe-' + _uid" @submit.prevent="send" aria-describedby="subscribe-desc" aria-labelledby="subscribe-heading">
		<h2 class="h3" id="subscribe-heading">{{$t('subscribe.title')}}</h2>
		<p id="subscribe-desc">{{$t('subscribe.description')}}</p>
		<div class="app-cb-step1 row">
			<div class="col-lg-8 col-md-12">
				<div class="app-start-control">
					<input 
					:value="email" 
					:id="'app-cb-email-' + _uid" 
					class="form-control" 
					:class="{'not-empty': email.length, 'is-valid': email_valid, 'is-invalid': input_invalid}" 
					type="email" 
					@input="change" 
					required 
					:aria-labelledby="hasMessagefieldRequired ? 'app-cb-message-field-required' : null"
					@keyup.enter="sendOnEnter"
					:aria-invalid="email_valid ? 'false' : 'true'">
					<label :for="'app-cb-email-' + _uid">{{$t('subscribe.your_email')}} <b class="app-field-required-sym">* <span class="sr-only">{{$t('field_required')}}</span></b></label>				
				</div>
				<div class="m-1 d-lg-none"></div>
				<label v-if="email_valid" :for="'callback_confirm' + _uid" class="app-checkbox subscribe-checkbox">
					<input 
					type="checkbox" 
					v-model="confirmated" 
					:id="'callback_confirm'+ _uid"
					:aria-labelledby="emailCompletedAndNotChecked ? 'app-cb-message-field-checkbox' : null">
					<span>
						<i aria-hidden="true"><svg class="ungic-icon" role="img" aria-hidden="true" width="2em" height="2em">
						<use href="#svg-icon-tick" /></svg></i>
						<i18n path="subscribe.confirm">
							<b v-text="email"></b>
						</i18n>
					</span>
				</label>
				<div class="alert mg" id="app-cb-message-field-required" :class="{'has-error-message': hasMessagefieldRequired}" v-if="hasMessagefieldRequired" role="alert">		
					<span>{{$t('subscribe.field_required_message') | capitalize}}</span>
				</div>
				<div class="alert mg" id="app-cb-message-field-checkbox" :class="{'has-error-message2': emailCompletedAndNotChecked}" v-if="emailCompletedAndNotChecked" role="alert">		
					<span>{{$t('subscribe.field_required_checkbox') | capitalize}}</span>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<button :class="{loading}" class="btn-outline-primary btn btn-md" :disabled="is_disabled" @click.prevent="send">{{$t('btns.subscribe')}}</button>
			</div>			
		</div>
		<div class="divider x2"></div>
		<div class="divider x2"></div>
		<div class="divider x2"></div>
		<div class="divider x2"></div>
		<div class="divider x2"></div>			
		<div class="alert mg" :class="{'alert-danger': error, 'alert-success': success && !error}" v-if="error || success" role="alert">
			<span v-if="!error && success">{{$t('subscribe.successfully_subscribed') | capitalize}}</span>
			<span v-if="error">{{$t('subscribe.subscription_failed') | capitalize}}</span>
		</div>
		<div v-if="form_valid">			
			<div class="grecaptcha">
				<svg class="ungic-icon" role="img" aria-hidden="true" width="2em" height="2em">
					<use href="#svg-icon-shield"></use>
				</svg>
				<recaptcha action="subscribe" ref="recaptcha" @ready="recaptcha = true"></recaptcha>
			</div>
		</div>	

	</form>
</template>
<script>
import filters from '@helpers/filters';
import { api } from "@api";

export default {
	filters,
	components: {
		recaptcha: () => import("@components/recaptcha.vue")
	},
	methods: {
		sendOnEnter() {
			if(this.is_disabled && !this.email) {			
				this.email = ' ';
			}
		},
		change: function(e) {
			this.email = e.target.value;
		},
		send: function() {
			if(this.is_disabled) {
				return
			}
			this.loading = true;
			this.success = false;
			this.error = false;
			this.$refs.recaptcha.execute().then(token => {
				api.post('v1/website/subscribe', {
						email: this.email,
						recaptcha_token: token
					})
					.then(result => {
						if (result.status >= 200 && result.status <= 204) {
							this.success = true;
						} else {
							this.error = true;
						}
					})
					.catch(e => {
						console.log(e);
						this.error = true;
					})
					.finally(() => (this.loading = false));
			});
		}
	},
	mounted: function() {
		this.email = '';
		this.$emit('mounted');
	},
	computed: {
			emailCompletedAndNotChecked() {
			return this.email_valid && !this.confirmated
		},
		hasMessagefieldRequired() {
			return (this.email && !this.email_valid)
		},
		is_disabled: function() {
			return (this.loading || !this.form_valid || !this.recaptcha);
		},
		email_valid: function() {
			return /.+@[^@]+\.[^@]{2,}$/.test(this.email);
		},
		form_valid: function() {
			return this.email_valid && this.confirmated
		},
		input_invalid: function() {
			return this.email && !this.email_valid;
		}
	},
	data: function() {
		return {
			recaptcha: false,
			email: '',
			error: '',
			success: '',
			loading: false,
			confirmated: false
		}
	}
}
</script>