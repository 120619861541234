
import Vue from 'vue'
import router from '@router'
import store from '@state/store'
import { i18n, routerHandler } from '@src/i18n/setup';
import App from './app.vue'
//import 'nprogress/nprogress.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { FocusTrap } from 'focus-trap-vue';
import './registerServiceWorker'
Vue.component('FocusTrap', FocusTrap);
import Affix from 'vue-affix';
Vue.use(Affix);


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';


Vue.directive('focus', {
	inserted: function(el) {
		Vue.nextTick(function() {
			el.focus()
		});
	},
	update: function(el, binding) {
		var value = binding.value;
		if (value) {
			Vue.nextTick(function() {
				el.focus();
			});
		}
	}
});
routerHandler(router);


import '@components/_globals'

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'


new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app')