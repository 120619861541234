<template>
      <affix relative-element-selector=".app-layout" :offset="{top: -100, bottom: 0}">
    <div class="main-navbar">     
      
            <b-navbar :aria-label="$t('aria_labels.main_nav')" toggleable="lg" type="light" >
                <div class="container">            
                    <b-navbar-brand :target="landingPage ? '_blank' : null" to="/">                
                        <base-icon class="mr-2" id="acb_logo" title="Accessibility Zone"></base-icon>
                        <span v-html="config.appName"></span>
                    </b-navbar-brand>
                    <b-navbar-toggle target="nav-collapse">
                        <template #default="{ expanded }">
                            <b-icon :aria-label="$t('sidebar.close')" v-if="expanded" icon="x"></b-icon>
                            <b-icon :aria-label="$t('sidebar.open')" v-else icon="list"></b-icon>
                        </template>
                    </b-navbar-toggle>
                    <b-collapse id="nav-collapse" is-nav>
                        <b-navbar-nav v-if="!landingPage" >    
                                
                            <b-nav-item v-for="item in navbarMenu" @click="afterClick(item)" :to="generateURL(item)" exact exact-active-class="active" :key="item.slug">
                            <span v-html="$t('navbar.' + item.slug)"></span>
                            </b-nav-item>      
                            
                        </b-navbar-nav> 

                        <!-- Right aligned nav items -->
                        <b-navbar-nav class="ml-auto ml-nav-2">
                            
                            <b-nav-item-dropdown :text="languageLabel">
                                <b-dropdown-item 
                                :active="isActiveLanguage(item.code)" 
                                :class="{'active': isActiveLanguage(item.code)}" 
                                :to="switchLanguage(item.code)" v-for="item,index in languages.supportedLanguages"  :key="item.code">{{item.title}}</b-dropdown-item>
                            </b-nav-item-dropdown>                        
                            <b-nav-item-dropdown class="app-user-menu" right v-if="loggedIn && !landingPage">
                                <!-- Using 'button-content' slot -->                   
                                <template #button-content>
                                    <b-avatar variant="light" :text="account.first_name[0].toUpperCase() + account.last_name[0].toUpperCase()" class="mr-3"></b-avatar>
                                    <span class="mr-auto sr-only" v-html="account.full_name"></span>
                                </template>
                                <b-dropdown-item :target="landingPage ? '_blank' : null" :to="'profile'|url">{{$t('user_menu.profile')}}</b-dropdown-item>
                                <b-dropdown-item :target="landingPage ? '_blank' : null" :to="'domains'|url">{{$t('user_menu.domains')}}</b-dropdown-item>
                                <b-dropdown-item :to="'logout'|url">{{$t('auth.logout')}}</b-dropdown-item>
                            </b-nav-item-dropdown>


                            <b-nav-item-dropdown class="app-notifications-dropdown" right v-if="loggedIn && !hideNotifications">
                                <template #button-content>
                                    <span class="app-usernotifi-box">
                                        <baseIcon id="bell_m" :title="$t('icons.notifications')"></baseIcon>
                                        <b-badge v-if="amountNewNotifications" variant="success"><b>{{amountNewNotifications}}</b></b-badge>
                                    </span>
                                </template>                            
                                <b-dropdown-item
                                :class="notifiClassElement(notifi)"
                                v-for="notifi in notifis" 
                                :target="landingPage ? '_blank' : null"
                                :to="'profile/notifications/' + notifi.id |url" 
                                :key="notifi.id" 
                                ><span v-html="notifi.title"></span> <baseIcon :title="$t('icons.is_new_notification')" id="email_m" v-if="notifi.is_new"></baseIcon></b-dropdown-item>
                            
                                <b-dropdown-item class="app-notifications-dropdown-llink" :target="landingPage ? '_blank' : null" :to="'/profile/notifications' | url">{{$t('pages.profile.navbar.items.notifi')}}</b-dropdown-item>
                            </b-nav-item-dropdown>      

                            <li class="nav-item app-login-controls" v-if="!loggedIn">
                                <router-link :to="setURL('login')" class="btn btn-outline-primary">{{$t('auth.sign_in')}}</router-link>                        
                            </li>
                        </b-navbar-nav>
                    </b-collapse>
                </div>
            </b-navbar>

    </div>
    </affix>
</template>
<script>
    let {authComputed, spaceMethods, authMethods} = require('@storage');
   
 

    import filters from '@helpers/filters';
    import navbar_links from '@src/static_api/navbar';
    export default {
        filters,
        props: {
            landingPage: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            notifis() {
                return this.$store.state.space.notifications
            },
            amountNewNotifications() {
                return this.$store.state.space.notifications.filter(e => e.is_new).length
            },
            isHome: function() {
                return this.$route.matched.some((route) => route.meta.name == 'home' || route.meta.name == 'index');
            },
            navbarMenu() {
                return navbar_links
            },
            config() {
                return this.$store.state.settings;
            },
            languages() {
                return this.$store.state.languages
            },
            languageLabel() {               
                return this.$t('language_picker.title', {language: this.$t('languages.' + this.currentLanguage)})
            },
            ...authComputed
        },
        data: function() {            
            return {
                currentLanguage: '',
                hideNotifications: false
            }
        },
        beforeMount() {
            this.currentLanguage = this.$route.params.lang;
            let _fetch = () => {
                this.fetchNotifis().catch(e => {
                    this.hideNotifications = true;
                });
            }
             
            if(this.loggedIn) {  
                this.$timer = setInterval(() => {              
                     _fetch();   
                }, 1 * 60 * 1000);
                _fetch();     
            } else {
                clearInterval(this.$timer);
            }   
        },
        beforeDestroy() {
            clearInterval(this.$timer);
        },
        methods: {         
            ...authMethods,   
            notifiClassElement(notifi) {
                let data = {
                    'app-notifications-dropdown-item': true
                }
                data[notifi.type] = true;
                data.is_new = notifi.is_new;
                return data;
            },
            ...spaceMethods,
            afterClick(item) {
                if(item.hash) {
                    window.scrollToHash(item.url);
                }
            },
            generateURL(item) {
                let r = this.$route;
               
                return r.meta.name == item.hash ? item.url : this.setURL(item.url);
            },
            setURL: function(url) {
                url = url.toString();
                if (this.isHome && /^\/#/.test(url)) {
                    url = url.slice(1, url.length);
                }
                if(!/^http/.test(url)) {
                    url = filters.url(url);
                }
                return url;
            },
            isActiveLanguage(code) {
                return code == this.currentLanguage;
            },
            switchLanguage(code) {
                //this.currentLanguage = code;
                let path = this.$route.fullPath.split('/');
                path = path.filter(p => p.trim() != '');
                path.shift();

                path.unshift(code);
                return '/' + path.join('/')
            }
        }
    }
</script>