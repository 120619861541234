<script>
    import Layout from '@layouts/main.vue'
    export default {
        page: function() {
            return this.$store.getters['settings/page'].call(this, 'error403')
        },
        components: { Layout }
    }
</script>
<template>
    <Layout>
       <div class="container text-center">
           <div class="box">
               <h1>
                   <a href="/" class="text-primary">Accessible Zone TimeOut</a>
               </h1>
                <b-jumbotron header="403" header-tag="h2" bg-variant="primary" text-variant="light" lead="The page timed out while loading. Are you sure you're still connected to the Internet?">
                    <hr class="my-4">
                    <a href="/" class="btn btn-dark btn-lg">{{$t('navbar.home')}}</a>
                </b-jumbotron>
           </div>
       </div>
    </Layout>
</template>