export const MODULE_ID = 'acsb-settings';

//import modules from '@state/modules'
import store from '@state/store';
import { mergeDeep } from '@helpers/common';
import { api } from "@api";
import config from "@config";
import router from '@router';

let fromStorage = window.localStorage.getItem(MODULE_ID);
if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}

const getDefaultState = () => {
  return {
    loading: true,
    online: true,
    hasError: false,
    hasModal: false,
    pageAfterLogin: 'dashboard',
    ungic: require('@ungic'),
    ...config
  }
}
export const state = getDefaultState();

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}
function hexToRGB(hex) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
  return r + "," + g + "," + b;//return 23,14,45 -> reformat if needed 
}

export const getters = {
  ungicColors: function (state) {
    return state.ungic.find(e => e.cid == "project").data.colors;
  },
  primaryColor: function (state) {
    return state.ungic.find(e => e.cid == "project").data.colors.primary
  },
  storage: function (state) {
    return state;
  },
  get: function (state) {
    return key => state[key]
  },
  page: function (state) {
    return function (pageID) {
      let pageMetaHandler = (page, common) => {
        if (!page.meta.length) {
          return page;
        }
        for (let meta of page.meta) {
          if (meta.i18n) {
            let name = (meta.name) ? meta.name : meta.property.replace(/[:.]+/g, '_');
            if (name == 'og_description') {
              name = 'description';
            }
            meta.content = this.$t('pages.' + (common ? 'common' : pageID) + '.meta.' + name);
            if (name == 'og_site_name') {
              meta.content = this.$t('pages.common.title');
            }
            if (name == 'og_title') {
              meta.content = this.$t('pages.' + pageID + '.title');
            }
            delete meta.i18n;
          }
        }
        return page;
      }

      let pages = state.pages;

      let storage = pages[pageID];
      if (!storage) {
        storage = pages['home'];
      }
      let page = JSON.parse(JSON.stringify(storage));
      if (pageID == 'common' && !pages[pageID]) {
        page = config.pages.common;
      }
      page.title = this.$t('pages.' + pageID + '.title');
      if (page.meta.length) {
        page = pageMetaHandler(page);
      }
      let dir = store.getters['languages/dir'];
      if (page.htmlAttrs) {
        page.htmlAttrs.dir = dir;
      } else {
        page['htmlAttrs'] = {
          dir
        }
      }
      let languages = store.getters['languages/languages'];
      let currentLanguage = store.getters['languages/currentLanguage'];
      let host = config.host;
      let route = router.history.current;
      let codes = languages.map(l => l.code);
      let links = page.links || [];
      for (let lang of languages) {
        links.push({
          rel: "alternate",
          hreflang: lang.code,
          href: host + '/' + route.fullPath.replace(/^\/(\w{2})/, function (math, code) {
            if (codes.indexOf(code) != -1) {
              return lang.code;
            }
          })
        });
        page.meta.push({
          property: lang.code == currentLanguage ? "og:locale" : "og:locale:alternate",
          content: lang.code
        });
      }
      page.link = links;
      let metaTags = page.meta;

      if (metaTags && metaTags.length) {

        if (!metaTags.find(el => el.property == 'og:title')) {         
          page.meta.push({
            property: 'og:title',
            content: this.$t('pages.' + pageID + '.title')
          });
        }
        if (!metaTags.find(el => el.property == 'og:description')) {
          page.meta.push({
            property: 'og:description',
            content: this.$t('pages.' + pageID + '.meta.description')
          });
        }
      }
      let commonMeta = pageMetaHandler(config.pages.common, true);
      if (!page.title) {
        page = commonMeta.title;
      }
      commonMeta = JSON.parse(JSON.stringify(commonMeta));
      let commonsMeta = commonMeta.meta;
      if (page.meta.find(el => el.name && el.name == 'description')) {
        commonsMeta.splice(commonsMeta.indexOf(commonsMeta.find(el => el.name && el.name == 'description')), 1);
      }
      if (page.meta.find(el => el.property && el.property == 'og:description')) {
        commonsMeta.splice(commonsMeta.indexOf(commonsMeta.find(el => el.property && el.property == 'og:description')), 1);
      }      
      if (page.meta.find(el => el.property && el.property == 'og:title')) {
        commonsMeta.splice(commonsMeta.indexOf(commonsMeta.find(el => el.property && el.property == 'og:title')), 1);
      }
      commonMeta.meta = commonsMeta;

      page = mergeDeep(commonMeta, page);
      return page;
    }
  }
}

export const mutations = {
  update: function (state, payload) {
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }
    saveState(state);
  },
  reset: function (state) {
    window.localStorage.removeItem(MODULE_ID);
    Object.assign(state, getDefaultState());
  },
  loaded: function (state) {
    state.loading = false
  }
}

export const actions = {
  initialize: function ({ commit }) {
    //return Promise.resolve();
    return new Promise((res) => {
      api.post('v1/settings/app/config')
        .then((result) => {
          let data = result.data;
          /*if(data.version > state.version) {
            for(let moduleName in modules) {
              if('reset' in modules[moduleName].actions) {
                store.dispatch(moduleName + '/reset');
              }
            }
          }*/
          if (data.data) {
            data = data.data;
          }
          commit('update', data);
          res();
        })
        .catch((e) => {
          commit('update', {
            prop: 'hasError',
            value: e
          });
          res();
        });
    });
  },
  loaded: function (ctx) {
    ctx.commit('loaded')
  },
  update: function (ctx, payload) {
    ctx.commit('update', payload)
  },
  reset: function (ctx) {
    ctx.commit('reset');
  }
}