import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    account: (state) => state.account,
  }),
  ...mapGetters('auth', ['loggedIn', 'account', 'isAdmin']),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut'])

export const privateAuthComputed = {
  ...mapState('privateAuth', {
    account: (state) => state.account,
  }),
  ...mapGetters('privateAuth', ['account', 'loaded', 'account_id']),
}
export const privateAuthMethods = mapActions('privateAuth', ['account', 'auth'])

export const spaceMethods = {
  ...mapActions('space', [
    'getDomains', 
    'fetchNotifis',
    'readNotification',
    'getAmountNotifications',
    'updateNotifiLocal',
    'getConnectCode',
    'setActiveDomain',
    'updateDomainPlugins',
    'updateDomain', 
    'domainNameCheck', 
    'domainVerifyConfirm', 
    'domainVerifyRequest', 
    'domainRegistration',
    'deleteDomain',
    'deleteDomains',
    "updateClientDomain",
    "fetchDomain"
  ])
}

export const privateSpaceMethods = {
  ...mapActions('privateSpace', [
    'getDomains',
    'getNotifications',
    'getAmountNotifications',
    'createNotification',
    'logIn',
    'getConnectCode',
    'setActiveDomain',
    'updateDomainPlugins',
    'updateDomain', 
    'domainNameCheck', 
    'domainVerifyConfirm', 
    'domainRegistration',
    'deleteDomain',
    'deleteDomains',
    "updateClientDomain",
    "fetchDomain"
  ])
}


export const acsbMethods = {
  ...mapActions('acsb', [
    'audit'
  ])
}

export const spaceComputed = {
  ...mapState('space', [
    'domains',
    'notifications'
  ])
}

export const privateSpaceComputed = {
  ...mapState('privateSpace', [
    'domains'
  ])
}

export const pluginMethods = {
  ...mapActions('plugins', [
    'fetchPlugin',
    "addToDomain",
    'fetchPlugins',
    'updateReference',   
    'removeReference',   
    'reset',
    'update',
    'getUiConfig',
    'savePluginConfig',
    'fetchPublicPlugins',
    'fetchPublicPluign'
  ])
}

export const pluginComputed = {
  ...mapState('plugins', [
    'storage',
    'plugins',
    'get'
  ])
}


export const privatePluginMethods = {
  ...mapActions('privatePlugins', [
    'fetchPlugin',
    "addToDomain",
    'fetchPlugins',
    'updateReference',   
    'removeReference',   
    'reset',
    'update',
    'getUiConfig',
    'savePluginConfig'
  ])
}

export const privatePluginComputed = {
  ...mapState('privatePlugins', [
    'storage',
    'plugins',
    'get'
  ])
}

