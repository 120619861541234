export function documentReadyCallback(callback) {
  if (document.readyState === "interactive" || document.readyState === "complete") {
    callback();
  } else {
    let done = function() {
      callback(...arguments);
      document.removeEventListener(done);
    }
    document.addEventListener('DOMContentLoaded', done);
  }
}
export function documentComplete(callback) {
  if (document.readyState === "complete") {
    callback();
  } else {
    let done = function() {
      callback(...arguments);
      window.removeEventListener('load', done);
    }
    window.addEventListener('load', done);
  }
}
export function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}
export function isVisible(element) {
	var position = element.getBoundingClientRect();
	return (element.offsetWidth > 0 && element.offsetHeight > 0) && (position.top < window.innerHeight && position.bottom >= 0) && (position.left < window.innerWidth && position.right >= 0);
}
export function globalKeypress(e) {
	if (document.activeElement) {
		if (document.activeElement.closest('.__stop-scroll-navigation') && (e.code == 'ArrowDown' || e.code == 'ArrowUp')) {
			e.preventDefault();
		}
	}
}
export function globalTrapOutside(e) {
	let activeEl = e.target;
	var event = new CustomEvent("clickoutside");
	document.querySelectorAll('.__trap-outside').forEach(function(el) {
		let closeTrapEl = activeEl.closest('.__trap-outside');
		let itSelf = activeEl.hasAttribute('.__trap-outside');
		if (!closeTrapEl && !itSelf) {
			el.dispatchEvent(event);
		} else {
			let checkEl = !itSelf ? closeTrapEl : activeEl;
			if (!el.contains(checkEl)) {
				el.dispatchEvent(event);
			}
		}
	});
}
export function checkVisibleEls() {
	document.querySelectorAll('.__is-visible, .img-lazy-loading').forEach(function(el) {
		if (isVisible(el)) {
			el.dispatchEvent(new CustomEvent('visible'));
		}
	});
}