import {auth, space, acsb, api, privateAcsb, privateAuth, privateSpace} from "@api";
export const MODULE_ID = 'acsb-auth';
import dayjs from "dayjs";
let fromStorage = window.localStorage.getItem(MODULE_ID);

if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}


const getDefaultState = () => {
  return {
    account: {}, 
    auth: {},
    access_token: null,
    refresh_token: null
  }
}

export const state =  Object.assign(getDefaultState(), fromStorage);

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}

export const getters = {
  isAdmin(state) {
    return state.account.role == 'administrator'
  },
  loggedIn(state) { 
    return !!(Object.keys(state.account).length)
  },
  account(state) {
    if(state.account.first_name && state.account.last_name) {
      state.account.full_name = state.account.first_name + ' ' + state.account.last_name;
    }
    if(state.account.created_at) {
      state.account.created = dayjs(state.account.created_at).calendar();
    }
    return state.account;
  }
}

export const mutations = {
  update(state, payload) { 
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }
    saveState(state);
  },
  logIn(state, payload) {
    state = Object.assign(state, payload);
    saveState(state);
  },
  reset: function(state) {
    window.localStorage.removeItem(MODULE_ID);
    let data = getDefaultState()  
    for(let key in data) {
      let val = data[key];
      state[key] = val;
    }    
    fromStorage = {}
    saveState(data);
  }
}

export const actions = {
  init({ dispatch, state, getters, commit }) {
  
    let setApiHeaders = api => {
      api.defaults.transformRequest = [function(data, headers) {
        if (getters.loggedIn) {
          headers.common['x-access-token'] = state.access_token;
          if(state.refresh_token) {
            headers.common['x-refresh-token'] = state.refresh_token;      
          }       
          headers.common['x-app-id'] = state.auth.appID;     
        }
        return data
      }, ...api.defaults.transformRequest];
    }
    
    setApiHeaders(api);
    setApiHeaders(auth);
    setApiHeaders(space);
    setApiHeaders(acsb);

    setApiHeaders(privateAcsb);
    setApiHeaders(privateAuth);
    setApiHeaders(privateSpace);   

    function logoutTrap(api) {
      api.defaults.transformResponse = [function(data) {   
        try {
          data = JSON.parse(data);         
          if(data.statusCode == 401 || data.statusCode == 426) { 
            if (getters.loggedIn) {
                dispatch('logOut');
            }          
          }
        } catch(e) {
        }
        return data
      }, ...api.defaults.transformResponse]
    }

    logoutTrap(space);
    logoutTrap(acsb);
    logoutTrap(auth);
    logoutTrap(api);
    

    auth.defaults.transformResponse = [function(data) {
      try {        
        data = JSON.parse(data);
        let updateIsHas = key => {
          if(data[key] !== undefined) {
            commit('update', {
              prop: key,
              value: data[key]
            });
          }
        }       
        updateIsHas('account');
        updateIsHas('access_token');
        updateIsHas('refresh_token');        
      } catch(e) {
      }
      return data
    }, ...auth.defaults.transformResponse]       
  },

  // Logs in the current user.
  logIn({dispatch, commit, state}, payload={}) {
    commit('logIn', payload);
    return Promise.resolve(state.account);
  },
  
  update({commit}, payload) {
    commit('update', payload);
  },

  // Logs out the current user.
  logOut({ commit }) {    
    return auth.put('/account/logout').then(e=> {
      commit('reset');
    }).catch(e => {      
      commit('reset');
      window.location.reload();
    })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({state, getters, commit, dispatch}) {
    
    if (!getters.loggedIn) {
      commit('reset');
      return Promise.resolve(null)
    }
    // auth check
    return new Promise(res => {     
      if (state.refresh_token) {
        auth.put('/account/swap_tokens').then(e => {    
          res(state.account);
          auth.get('/account').then(e => {           
            commit('update', {account: e.data});           
          }).catch(e => {
            console.error('get account error:' + e.message)
          });    
        }).catch(() => {          
          commit('reset');
          res(false);
        });
      } else {
        auth.get('/account').then(e => {
          commit('update', {account: e.data});
          res(state.account);
        }).catch(() => {
          commit('reset');
          res(false);
        });
      }
    });     
  }
}