export default [
	{
		slug: 'dashboard',
		url: '/dashboard'
	},
	{
		slug: 'contact_us',
		url: '/contact-us'
	},
	{
		slug: 'our_services',
		url: '#services-heading',
		hash: 'home'
	},
	{
		slug: 'about',
		url: '/about',
		hash: 'about'
	},
	{
		slug: 'plugins',
		url: '/plugins',
		hash: 'plugins'
	}
]