<script>
	export default {
		name: 'Heading',
		props: {
			tag: {
				type: String,
				default: 'h1'
			}
		},
		render: function(createElement) {
			return createElement(this.tag, this.$slots.default)
		}
	}
</script>