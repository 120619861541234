import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
//import NProgress from 'nprogress/nprogress'
import routes from './routes'
import store from '@state/store'
import scrollto from "@utils/scrollto"
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules';

//import {loadLanguageAsync} from '@src/i18n/utils';

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page'
})

const router = new VueRouter({
    // Simulate native-like scroll behavior when navigating to a nelast-routew
  // route and using back/forward buttons.
  /*scrollBehavior(to, from, savedPosition) {    
    if (savedPosition) {     
      return savedPosition
    } else {
      let lastRoute = window.localStorage.getItem('app-last-route');
      if(lastRoute && lastRoute == to.fullPath) {
        let lastPositions = window.localStorage.getItem('app-last-scroll-position');
        if(lastPositions) {
          let [x,y] = lastPositions.split(',');
          return {x, y}
        }
      }  
      return { x: 0, y: 0 }
    }
  },*/
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history'
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  //NProgress.start();
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const authAdminRequired = routeTo.matched.some((route) => route.meta.authAdminRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired && !authAdminRequired) return next();

  // If auth is required and the user is logged in...
  if (store.getters['auth/loggedIn']) {
    // Validate the local user token...
    return store.dispatch('auth/validate').then((validUser) => {
      // Then continue if the token still represents a valid user,
      // otherwise redirect to login.
      dispatchActionForAllModules('afterLogin');
      if(validUser) {
        if(!authAdminRequired) {  
          return next();
        } else {
          if(store.getters['auth/isAdmin']) {
            let ref = routeTo.params.ref;
            if(!ref) {
              return next();
            }
            const privateSpaceAuth = routeTo.matched.some((route) => route.meta.privateSpaceAuth);
          
          
            store.dispatch('privateAuth/auth', ref).then(account => {              
              if(!account) {
                return next({
                  name: '403'
                });
              } else {
                if(privateSpaceAuth) {
                  store.dispatch('privateSpace/auth', ref).then(account => {
                    if(account) {
                      next();
                    } else {
                      next({
                        name: '403'
                      });
                    }
                  }).catch(() => {
                    next({
                      name: '403'
                    });
                  })
                } else {
                  next();
                }
              }         
            });
          } else {
            return next({
              name: '403'
            });
          }
        }
      } else {
        redirectToLogin()
      }      
    })
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin()

  function redirectToLogin() {    
    //console.log('redirectToLogin', routeFrom);
    // Pass the original route to the login component
    if(routeFrom.meta.name == 'login') {
      //NProgress.done();
    }    
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
  
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).

  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
               // NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

window.scrollToHash = function(hash) {
  try {
    scrollto(document.querySelector(hash), 300);
  } catch(e) {

  }

}

// When each route is finished evaluating...
router.afterEach((routeTo, from) => {  

  let lastRoute = window.localStorage.getItem('app-last-route');
  if(lastRoute && lastRoute == routeTo.fullPath) {
    let lastPositions = window.localStorage.getItem('app-last-scroll-position');
    if(lastPositions) {
      let [x,y] = lastPositions.split(',');
      window.scrollTo(x, y);
    }
  } else {
    window.scrollTo(0, 0);
  }

  // Complete the animation of the route progress bar.
  // scrollto
  window.localStorage.setItem('app-last-route', routeTo.fullPath);
  //console.log(routeTo.hash);
  try {   
    setTimeout(() => {
      if(routeTo.hash && document.querySelector(routeTo.hash)) {
        scrollToHash(routeTo.hash);
      }
    }, 500);
  } catch(e) {
    //console.log(e);
  }
  //NProgress.done();
});


export default router
