export const MODULE_ID = 'acsb-private-plugins-state';

import { privateSpace } from "@api";


let fromStorage = window.localStorage.getItem(MODULE_ID);
if (fromStorage) {
  try {
    fromStorage = JSON.parse(fromStorage);
  } catch (e) {
    console.log(e);
  }
} else {
  fromStorage = {}
}

const getDefaultState = () => {
  return {
    plugins: []
  }
}
export const state = getDefaultState();

function saveState(state) {
  window.localStorage.setItem(MODULE_ID, JSON.stringify(state));
}

export const getters = {
  storage: function(state) {
    return state;
  },
  get: function(state) {
    return key => state[key]
  }
}
export const mutations = {
  update: function(state, payload) {
    if (payload.prop && state[payload.prop] !== undefined) {
      let { prop, value } = payload;
      state[prop] = value;
    } else {
      state = Object.assign(state, payload);
    }
    saveState(state);
  },
  reset: function(state) {
    window.localStorage.removeItem(MODULE_ID);
    Object.assign(state, getDefaultState());
  },
  resetPlugins(state) {
    state.plugins = [];
  },
  setPlugin: function(state, plugin) {
    let prev = state.plugins.find(d => d.id == plugin.id);
    if(prev) {
      prev = Object.assign(prev, plugin);
    } else {
      state.plugins.push(plugin);    
    }
  },
  removePlugin(state, plugin_id) {
    state.plugins = state.plugins.filter(e => e.id != plugin_id);
  },
  addPlugin: function(state, plugin) {
    state.plugins.push(plugin);         
  },
  deletePlugin(state, id) {
    state.plugins = state.plugins.filter(e => e.id != id);          
  },
  setPlugin: function(state, payload) {
    let prev = state.plugins.find(p => p.id == payload.id);
    if(prev) {
        Object.assign(prev, payload);
    } else {
        state.plugins.push(payload);
    }
  }
}
function configPlugin(config={}, {domain_id, plugin_id, plugin_version}) {
    if(!config.headers) {
        config.headers = {}
    }
    config.headers['x-domain-id'] = domain_id;
    config.headers['x-plugin-id'] = plugin_id;
    if(plugin_version) {
      config.headers['x-plugin-version'] = plugin_version;
    }
    return config
}
export const actions = {
  fetchPlugin({commit, state}, {config={}, domain_id, plugin_id}) {   
    return new Promise((done, reject) => {
      privateSpace.get('account/plugin/domain', configPlugin(config, {domain_id, plugin_id})).then(e => {
        commit('setPlugin', e.data);
        done(e.data);
      }).catch(reject);
    })
  }, 
  getUiConfig({commit, state},  data={}) { 
    if(!data.data) {
      data.data = {}
    }
    if(!data.config) {
      data.config = {}
    }
    return new Promise((done, reject) => {
      privateSpace.get('plugin/ui_config', configPlugin(data.config, {domain_id: data.domain_id, plugin_version: data.plugin_version, plugin_id: data.plugin_id})).then(e => {        
        done(e.data);
      }).catch(reject);
    })
  },
  savePluginConfig(ctx, data={}) {
    if(!data.data) {
      data.data = {}
    }
    if(!data.config) {
      data.config = {}
    }
    return privateSpace.put('account/plugin/settings', data.data, configPlugin(data.config, {domain_id: data.domain_id, plugin_id: data.plugin_id})).then(e => {
      return e.data;
    });  
  },
  addToDomain(ctx, data={}) {
    if(!data.data) {
      data.data = {}
    }
    if(!data.config) {
      data.config = {}
    }
    return privateSpace.post('account/plugin/reference', data.data, configPlugin(data.config, {domain_id: data.domain_id, plugin_id: data.plugin_id})).then(e => {
      return e.data;
  });  
  },
  fetchPlugins({commit, state}, data={}) {
    return privateSpace.get('plugins/all', data.config || {}).then(e => {
      for(let plugin of e.data) {
        commit('setPlugin', plugin);
      }
      return e.data;
    });
  },
  removeReference: function(ctx, {config={}, data, domain_id, plugin_id}) {
    return privateSpace.delete('account/plugin/reference', configPlugin(config, {domain_id, plugin_id})).then(e => {  
        ctx.commit('removePlugin', plugin_id);
        return e.data;
    });    
  },
  updateReference: function(ctx, {config={}, data, domain_id, plugin_id}) {
    return privateSpace.put('account/plugin/reference', data, configPlugin(config, {domain_id, plugin_id})).then(e => {    
        ctx.dispatch('fetchPlugin', {
            domain_id,
            plugin_id
        });
        return e.data;
    });    
  },
  update: function(ctx, payload) {
    ctx.commit('update', payload)
  },
  reset: function(ctx) {
    ctx.commit('reset');
  }
}