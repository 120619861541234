
<script>
    import filters from '@helpers/filters';
    import { spaceMethods, spaceComputed } from "@storage";

    export default {
        name: "baseNotifis",
        filters,     
        props: {
            apiType: String
        },
        data: {
            onlyPageReady: false
        },
        computed: {
            ...spaceComputed,
            items: function() {          
                return this.notifications;
            }
        },
        data() {
            return {
                activeNotifiID: null,
                openElement: {}
            }
        },
        watch: {
            openElement(item) {
                if(typeof item == 'object' && Object.keys(item).length) {
                    this.updateNotifiLocal({
                        id: item.id,
                        data: {
                            opened: !item.opened
                        }
                    }); 
                    this.readNotification({
                        id: item.id
                    });
                }
            },
            activeNotifiID(id) {
                if(id && this.items.length) {   
                    let item = this.items.find(item => item.id == id);
                    this.openTo(item);
                }
            },
            items(els) {
                if(els && els.length && this.activeNotifiID) {
                    let item = this.items.find(item => item.id == this.activeNotifiID);
                    this.openTo(item);
                }
            }
        },  
        mounted() {
            if(this.$route.params  && this.$route.params.notifi_id) {
                this.activeNotifiID = this.$route.params.notifi_id;         
                this.onlyPageReady = true;     
            }
        },
        beforeMount() {    
            this.fetchNotifis().catch(e => {
                this.pushError(
                    this.$t('domain.modals.common.error.title'),
                    e
                );
            }).finally(() => {
                this.$emit('loading');
            });                   
        },
        methods: {
            ...spaceMethods,
            classElement(item) {
                let data = {}
                data[item.type] = true;
                data.is_new = item.is_new
                return data;  
            },
            openTo(item) {     
                this.onlyPageReady = false;    
                this.openElement = {}     
                this.openElement = item;   
            }
        }
    }
</script>
<template>
    <div class="app-notifications" ref="box" tabindex="0" :aria-label="$t('notifications.title')">
        <div class="app-notifications-notifi" aria-label="" v-for="item in items" :key="item.id" :class="classElement(item)">
            <button v-focus="activeNotifiID == item.id && onlyPageReady" :id="'btn_' + item.id" class="app-notifications-notifi-summary" type="button"             
                :aria-expanded="item.opened" 
                :aria-controls="'app_details_element' + item.id" @click.prevent="openTo(item)">        
                 
                <span v-html="item.title"></span> <baseIcon :title="$t('icons.is_new_notification')" id="email_m" v-if="item.is_new"></baseIcon>
            </button>
            <div role="region" :aria-labelledby="'btn_' + item.id" class="app-notifications-notifi-section" :id="'app_details_element' + item.id" v-if="item.opened">
                <div v-html="item.message"></div>
            </div>
        </div>        
    </div>
</template>