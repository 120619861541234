<script>
    import {render} from '@ungicIcons';
    import Vue from 'vue'
    export default {
        functional: true,
        props: {
            title: String,
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            id: {
                type: String    
            },
            presentation: {
                type: Boolean
            }
        },
        render(createElement, ctx) {    
            if(ctx.props.title) {
                ctx.props.options.title = ctx.props.title;
            }
            if(ctx.props.presentation) {
                ctx.props.options.presentation = ctx.props.presentation;
            }
            if(ctx.data.staticClass) {
                ctx.props.options.class = ctx.data.staticClass;
            }
        
            try {
                let icon = render(ctx.props.id, ctx.props.options); 
                return createElement('span', {
                    class: {
                        'ungic-icon-box': true
                    },                
                    domProps: {
                        innerHTML: icon
                    }
                });
            } catch(e) {
                return createElement('span');
            }
            
        }
    }
</script>
