<script>
    import Layout from '@layouts/main.vue'
    export default {
        page: function() {
            return this.$store.getters['settings/page'].call(this, 'error404')
        },
        components: { Layout }
    }
</script>
<template>
    <Layout>
       <div class="container text-center">
           <div class="box">
               <h1>
                   <a href="/" class="text-primary"><svg aria-hidden="true" class="mr-2" width="2em" height="2em"><use xlink:href="#svg-icon-acb_logo"></use></svg> Accessibility Zone</a>
               </h1>
                <b-jumbotron header="404" header-tag="h2" bg-variant="primary" text-variant="light" lead="Don't worry, just go to the home page and start your new way.">
                    <hr class="my-4">
                    <a href="/" class="btn btn-dark btn-lg">{{$t('navbar.home')}}</a>
                </b-jumbotron>
           </div>
       </div>
    </Layout>
</template>