<template>

	<transition name="fade2">
		<div v-if="show && loaded" ref="el" class="app-cookies-confirmation">
			<div class="app-cookies-confirmation-container">
				<div class="container">
					<div class="row">
						<div class="cell">
							<i18n path="cookies.message" tag="p">
								<a :href="'/cookie-policy'|url">{{$t('cookies.link')}}</a>
							</i18n>
						</div>
						<div class="cell">
							<button class="btn btn-primary btn-lg btn-sm" @click.prevent="submit" ref="submit">{{$t('btns.ok_thanks')}}</button>
						</div>
					</div>
				</div>
			</div>
			<div class="app-cookies-confirmation-overlay" v-if="overlay"></div>
		</div>
	</transition>
	
</template>
<script>
import filters from '@helpers/filters';
export default {
	data: function() {
		return {
			confirmed: false
		}
	},
	filters,
	props: {
		overlay: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		show(status) {
			this.bodyClassHandler();
			if(status) {
				this.createBodyPaddingHandler();
			} else {
				this.removeBodyPaddingHanlder();
			}
		}
	},
	mounted: function() {
		this.bodyClassHandler();
		this.createBodyPaddingHandler();
	},
	beforeDestroy: function() {
		this.removeBodyPaddingHanlder();
	},
	computed: {
		show: function() {
			return !this.confirmed && !window.localStorage.getItem('acb_cookies_confirmed');
		},
		loaded() {
			return !window.__PRERENDER_INJECTED
		}
	},
	methods: {
		removeBodyPaddingHanlder() {
			window.removeEventListener('resize', this.$updatePadding);
			document.body.style.paddingBottom = 0;
		},
		createBodyPaddingHandler() {
			this.updateBodyPadding();
			if(!this.$updatePadding) {
				this.$updatePadding = this.updateBodyPadding.bind(this);
				window.addEventListener('resize', this.$updatePadding);
			}
		},
		updateBodyPadding() {
			if(this.show) {
				let {height} = this.$refs.el.getBoundingClientRect();
				document.body.style.paddingBottom = Math.round(height) + 'px';	
			} else {
				document.body.style.paddingBottom = 0;
			}					
		},
		bodyClassHandler() {
			if(this.show) {
				document.body.classList.add('has-cookie-notifi');
			} else {
				document.body.classList.remove('has-cookie-notifi');
			}
		},
		/*switchAria: function() {
			this.$store.dispatch('settings/update', {
				prop: 'hasModal',
				value: this.show
			});
		},*/
		submit: function() {
			window.localStorage.setItem('acb_cookies_confirmed', true);
			this.confirmed = true;
		}
	}
}
</script>