<template>
	<div>
		<div class="app-home-footer">
			<div class="container">
				<div class="row drow">
					<div class="cell">
						<Subscribe v-on:mounted="componentMounted"></Subscribe>
					</div>
					<div class="cell">
						<ContactUs headingTag="h2" v-on:mounted="componentMounted"></ContactUs>
					</div>
				</div>
			</div>
		</div>
		<BaseCopyright v-on:mounted="componentMounted"></BaseCopyright>
	</div>
</template>
<script>
	import Subscribe from '@components/subscribe';
	import ContactUs from '@components/contact-form';
	export default {
		components: {
			Subscribe,
			ContactUs
		},
		watch: {
			ready: function(status) {
				if(status) {
					this.$emit('mounted');
				}
			}
		},
		data: function() {
			return {
				ready: false,
				mountedСomponents: 0,
				amountSubComponents: 3
			}
		},
		methods: {
			componentMounted() {
				this.mountedСomponents += 1;
				if(this.mountedСomponents == this.amountSubComponents) {
					this.ready = true;
				}
			}
		}
	}
</script>